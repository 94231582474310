/* eslint-disable import/no-extraneous-dependencies */
'use client';

import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { sameDayShowTime } from '@fe-common-utils/libs/time';
import { styled } from '@linaria/react';
import Link from 'next/link';
import { FC } from 'react';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import type { ColumnistsType } from './SearchNews';
import Image from 'next/image';
import { GtmEventType } from '@fe-common-ui/ReadMore/ReadMore';

export enum CategoryNewsListGtmEventType {
  NEWS_CATEGORY = 'newsCategory',
  NEWS_CATEGORY_INTERESTED = 'newsCategoryInterested'
}

type NewsListProps = {
  title: string;
  newsId: number;
  coverSrc?: string;
  publishAt: number;
  target?: '_self' | '_blank';
  breadcrumbs?: string;
  style?: React.CSSProperties;
  Tags?: React.ReactNode;
  backgroundColor?: string;
  isHeadline?: 0 | 1;
  enableShowHeadlineMark?: boolean;
  isLast?: boolean;
  gtmEventType?: CategoryNewsListGtmEventType;
  isCelebrityArea?: boolean;
  summary?: string;
  columnists?: ColumnistsType | null;
  gtmEvent?: GtmEventType;
  isPortfolios?: boolean;
};

const ListContainerBase = styled.li<{
  backgroundColor?: string;
  coverSrc?: string;
  isLast?: boolean;
  isCelebrityArea?: boolean;
  isPortfolios?: boolean;
}>`
  list-style: none;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #383838;
  display: flex;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  padding-bottom: 16px;
  gap: 12px;
  position: relative;
  border-bottom: ${({ isLast }) => (isLast ? 0 : '1px solid #e2e8f1')};

  .cover-image {
    position: relative;
    width: 126px;
    height: 88px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #e2e8f1;
    margin-top: 4px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: ${({ coverSrc }) => `url(${coverSrc})` ?? ''};
      background-image: '';
      background-size: cover;
      background-position: center;
      transition: 0.4s;
    }
  }

  &:hover {
    .cover-image::after {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    background-color: ${({ isCelebrityArea, backgroundColor }) =>
      isCelebrityArea ? 'white' : backgroundColor || 'white'};

    padding: ${({ isCelebrityArea, isPortfolios }) =>
      isCelebrityArea ? '16px 12px' : isPortfolios ? '16px 0px' : '0px'};
  }
`;

const Content = styled.div`
  text-decoration: none;
  color: #383838;
  flex: 1;

  :hover {
    color: black;
  }
`;

const Title = styled.p<{
  isCelebrityArea?: boolean;
  isPortfolios?: boolean;
}>`
  font-size: 20px;
  line-height: 1.5;
  color: #383838;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: ${({ isCelebrityArea }) => (isCelebrityArea ? 2 : 1)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    -webkit-line-clamp: ${({ isPortfolios, isCelebrityArea }) => (isPortfolios || isCelebrityArea ? 2 : 1)};
  }
`;

const TitleLink = styled(Link)`
  :hover {
    color: black;
  }

  :visited {
    color: rgba(115, 115, 115, 0.5);
  }
`;

const NewsDate = styled.time`
  color: #848d97;
  margin-right: 20px;
`;

const ContentHeader = styled.p`
  font-size: 13px;
  margin-bottom: 15px;
  line-height: 13px;
  display: flex;
  align-items: center;

  span {
    color: #515c69;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 2px;
      background-color: #e2e8f1;
    }
  }
`;

const HeadlineIcon = styled.img`
  position: absolute;
  left: -42px;
  top: 24px;
`;

const Summary = styled.p<{
  isCelebrityArea?: boolean;
}>`
  font-size: 14px;
  line-height: 1.71;
  color: #777777;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: ${({ isCelebrityArea }) => (isCelebrityArea ? 2 : 1)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Figure = styled.figure`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ccc none 50% no-repeat;
  background-size: cover;
  overflow: hidden;

  img {
    z-index: 1;
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;
  }
`;

const ColumnistAuthorName = styled.p`
  margin-left: 7.5px;
  font-size: 14px;
  color: #383838;

  :hover {
    color: #54b5c6;
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    max-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Divide = styled.div`
  background-color: #eaeef5;
  width: 2px;
  height: 16px;
  margin: 0 8px;
`;

const CategoryNewsList: FC<NewsListProps> = ({
  title,
  newsId,
  coverSrc,
  publishAt,
  breadcrumbs,
  Tags,
  backgroundColor,
  style,
  enableShowHeadlineMark,
  isHeadline,
  isLast,
  gtmEventType,
  isCelebrityArea,
  summary,
  columnists,
  gtmEvent,
  isPortfolios = false
}) => {
  const url = `/news/id/${newsId}`;
  const isShowHeadline = enableShowHeadlineMark && isHeadline !== 0;

  const onClick = () => {
    if (gtmEvent) {
      sendGTM(gtmEvent);
      return;
    }
    if (gtmEventType) {
      if (gtmEventType === CategoryNewsListGtmEventType.NEWS_CATEGORY_INTERESTED) {
        sendGTM({
          [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
          [GTMEventKey.SECTION]: `${GTMConstants.SECTION_PLACEHOLDER}_可能感興趣`,
          [GTMEventKey.CLICK_ITEM]: `${newsId}_${title}`
        });
        return;
      }
      if (gtmEventType === CategoryNewsListGtmEventType.NEWS_CATEGORY) {
        sendGTM({
          [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
          [GTMEventKey.SECTION]: GTMConstants.SECTION_PLACEHOLDER,
          [GTMEventKey.CLICK_ITEM]: `${newsId}_${title}`,
          [GTMEventKey.LABEL]: `%p`
        });
        return;
      }
    }
  };

  return (
    <ListContainerBase
      key={`news_item_${newsId}`}
      backgroundColor={backgroundColor}
      coverSrc={coverSrc}
      style={style}
      isLast={isLast}
      isCelebrityArea={isCelebrityArea}
      isPortfolios={isPortfolios}
    >
      {isShowHeadline && (
        <HeadlineIcon src='/assets/icons/lists/headline.svg' width={38} height={41} alt='headline news icon' />
      )}
      <Content>
        <ContentHeader>
          {isCelebrityArea && columnists && (
            <>
              <Figure>
                <Image
                  src={columnists.image.s.src}
                  alt='columnists'
                  width={columnists.image.s.width}
                  height={columnists.image.s.height}
                />
              </Figure>
              <ColumnistAuthorName title={columnists.name}>{columnists.name}</ColumnistAuthorName>
              <Divide />
            </>
          )}
          <NewsDate suppressHydrationWarning>
            {typeof publishAt === 'string' ? publishAt : sameDayShowTime(publishAt)}
          </NewsDate>
          <span suppressHydrationWarning>{breadcrumbs}</span>
        </ContentHeader>
        <Title
          className='list-title'
          onClick={onClick}
          title={title}
          isCelebrityArea={isCelebrityArea}
          isPortfolios={isPortfolios}
        >
          <TitleLink href={url} title={title}>
            {title}
          </TitleLink>
        </Title>
        {isCelebrityArea && <Summary isCelebrityArea={isCelebrityArea}>{summary}</Summary>}
        {Tags && Tags}
      </Content>
      {coverSrc && !isCelebrityArea && <div className='cover-image' />}
    </ListContainerBase>
  );
};

export default CategoryNewsList;
