/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Container = styled.section`
  width: 100%;
  margin-top: 35px;
  margin-bottom: 24px;
  font-size: 14px;
  height: 423px;
`;

const ContentWrapper = styled.div`
  padding: 16px 0;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  min-height: 324px;
`;

const TabsWrapper = styled.div`
  display: flex;
  width: 102px;
`;

const TagsWrapper = styled.div`
  flex: 1;
  margin-top: 12px;
`;

const NewsRecentTopic = () => {
  return (
    <Container>
      <Skeleton height={38} borderRadius={6} />
      <ContentWrapper>
        <TabsWrapper>
          <Skeleton height={22} width={102} borderRadius={6} />
        </TabsWrapper>
        <TagsWrapper>
          <Skeleton height={300} borderRadius={6} />
        </TagsWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default NewsRecentTopic;
