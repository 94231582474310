import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Wrapper = styled.div`
  width: 100%;
  line-height: 1;
  height: 330px;
`;

const LiTVSkeleton = () => {
  return (
    <Wrapper>
      <Skeleton height={330} />
    </Wrapper>
  );
};

export default LiTVSkeleton;
