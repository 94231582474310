import { fetchNews24hList } from '@fe-news/actions/news';
import { GETv1News24hList } from '@fe-news/api/news';
import { GETv1QuotesBySymbols } from '@fe-news/api/quotes';
import { NEWS_24H_NEEDED } from '@fe-news/constants/news/default-value';
import { useQuery } from '@tanstack/react-query';

const DEFAULT_VALUE = {
  newsId: '',
  title: '',
  otherProduct: [],
  publishAt: Date.now(),
  coverSrc: {
    xs: {
      src: '',
      width: 0,
      height: 0
    },
    s: {
      src: '',
      width: 0,
      height: 0
    },
    m: {
      src: '',
      width: 0,
      height: 0
    },
    l: {
      src: '',
      width: 0,
      height: 0
    },
    xl: {
      src: '',
      width: 0,
      height: 0
    },
    xxl: {
      src: '',
      width: 0,
      height: 0
    }
  },
  categoryName: '',
  keyword: []
};

const DEFAULT_VALUE_ARRAY = Array.from({ length: NEWS_24H_NEEDED }).fill(DEFAULT_VALUE);

const successStatusCode = 200;

export const fetchQuotesBySymbolsKeys = async (symbols: string[] = ['']) => {
  /* 側邊欄列表只取得第一筆商品 */
  try {
    if (symbols.length === 0) return [];

    const response = await GETv1QuotesBySymbols(symbols);

    if (response.statusCode === successStatusCode) {
      const markets = response?.data;

      return markets;
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchNews24h = async (limit: number) => {
  try {
    const data = await GETv1News24hList();

    if (data.statusCode === successStatusCode) {
      const responseData = data?.items?.data?.slice(0, limit) ?? DEFAULT_VALUE;
      return responseData;
    }

    return DEFAULT_VALUE_ARRAY;
  } catch (error) {
    console.error(error);
  }

  return DEFAULT_VALUE_ARRAY;
};

const useFetchNews24h = ({ limit = 0 }: { limit: number }) => {
  const { data: newsListData, isFetching } = useQuery({
    queryKey: ['fetch-news-24h-list', NEWS_24H_NEEDED],
    queryFn: () => fetchNews24hList({ limit: limit }),
    staleTime: 10 * 60 * 1000
  });

  return { news: newsListData, isLoading: isFetching };
};

export default useFetchNews24h;
