/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const coverHeight = 103.5;

const YesClubAdContainer = styled.section`
  .yes-club {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const AsideYesClubAd = () => {
  return (
    <YesClubAdContainer>
      <Skeleton height={coverHeight} borderRadius={6} />
    </YesClubAdContainer>
  );
};

export default AsideYesClubAd;
