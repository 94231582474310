'use client';
import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
/* eslint-disable import/no-extraneous-dependencies */
import useClickOutside from '@fe-common-utils/libs/hooks/use-click-outside';
import { styled } from '@linaria/react';
import cx from 'classnames';
import { useRef, useState } from 'react';

type ItemValue = number | string;

type GtmEventType = {
  [GTMEventKey.EVENT_NAME]: CustomEventName;
  [GTMEventKey.SECTION]?: string;
  [GTMEventKey.CLICK_ITEM]?: string;
};

type TabProps = {
  active: number;
  onClick: (n: number, v: ItemValue) => void;
  limit?: number;
  items: { label: string; value: ItemValue }[];
  gtmEvent?: GtmEventType;
};

const Container = styled.span`
  background-color: #f6f8fc;
  border-radius: 10px;
  padding: 3px;
  position: relative;
`;

const LabelButton = styled.button`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #515c69;
  padding: 4px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 30px;

  &.active {
    background: white;
    color: #e03f19;
    box-shadow: 0 0 6px 0 rgba(0, 65, 143, 0.1);
    border: solid 1px rgba(0, 65, 143, 0.1);
  }
`;

const MoreButton = styled.button`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #515c69;
  padding: 4px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 30px;

  ::after {
    content: '';
    background-image: url('/assets/icons/arrows/arrow-down-10x6.svg');
    width: 10px;
    height: 6px;
    margin-left: 4px;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  &.active {
    background-color: #fff;
  }
`;

const MoreDropDown = styled.ul`
  z-index: 3;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 30px;
  right: 12px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  box-shadow: rgba(0, 65, 143, 0.1) 0px 0px 3px 0px;
  border: 0px solid rgb(226, 232, 241);
`;
const MoreDropDownItem = styled.li`
  padding: 8px 11px;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: rgb(81, 92, 105);

  &.active {
    background: white;
    color: #e03f19;
  }
`;

const SelectedPeriod = styled.span`
  color: #e03f19;
`;

export const Tabs = ({ active = 0, items, onClick, limit = 2, gtmEvent }: TabProps) => {
  const len = items.length;
  const isShowMore = len > limit;
  const [isOpenMore, setIsOpenMore] = useState<boolean>(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = () => {
    setIsOpenMore(false);
  };

  useClickOutside(dropdownRef, handleClickOutside);

  const handleClickMore = () => {
    setIsOpenMore(v => !v);
  };

  const handleClick = (index: number, value: ItemValue) => () => {
    onClick(index, value);

    if (gtmEvent) {
      sendGTM(gtmEvent);
    }

    if (index >= limit) {
      setIsOpenMore(false);
    }
  };

  return (
    <Container className='tabs-selector'>
      {items.slice(0, limit)?.map(({ label, value }, index: number) => (
        <LabelButton
          key={`tab-${index}`}
          className={cx({ active: active === index })}
          onClick={handleClick(index, value)}
        >
          <span>{label}</span>
        </LabelButton>
      ))}
      {isShowMore && (
        <>
          <MoreButton onClick={handleClickMore} className={cx({ active: active >= limit })}>
            {active < limit ? '更多' : <SelectedPeriod>{items[active].label}</SelectedPeriod>}
          </MoreButton>
          {isOpenMore && (
            <MoreDropDown ref={dropdownRef}>
              {items.slice(limit).map(({ label, value }, index: number) => (
                <MoreDropDownItem
                  key={`tab-more-${index}`}
                  className={cx({ active: active === index + limit })}
                  onClick={handleClick(index + limit, value)}
                >
                  <span>{label}</span>
                </MoreDropDownItem>
              ))}
            </MoreDropDown>
          )}
        </>
      )}
    </Container>
  );
};

export default Tabs;
