// TODO: 待刪除

/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Container = styled.section`
  width: 100%;
  height: 234px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

const NewsWrapper = styled.div`
  flex: 1;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const LogoWallSkeleton = () => {
  return (
    <Container>
      <NewsWrapper>
      <Skeleton height={234} borderRadius={6} />
      </NewsWrapper>
    </Container>
  );
};

export default LogoWallSkeleton;
