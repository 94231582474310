/* eslint-disable import/no-extraneous-dependencies */
'use client';

import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { GtmEventType } from '@fe-common-ui/ReadMore/ReadMore';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import { memo } from 'react';

export enum AsideNewsGtmEventType {
  NEWS_24H = 'news24h',
  TW_STOCK_EXPERT = 'twStockExpert',
  ISSUE_NEWS = 'issueNews',
  NEWS_LIST = 'newsList'
}
const ASIDE_NEWS_GTM_SECTION = {
  news24h: `${GTMConstants.SECTION_PLACEHOLDER}_主編精選`,
  twStockExpert: `${GTMConstants.SECTION_PLACEHOLDER}_台股專家觀點`,
  issueNews: `${GTMConstants.SECTION_PLACEHOLDER}_議題新聞`,
  newsList: `${GTMConstants.SECTION_PLACEHOLDER}`
};

type NewsThemeProps = {
  title: string;
  newsId: number;
  coverSrc?: string;
  publishAt: number | string;
  target?: '_self' | '_blank';
  fontSize?: number;
  lineHeight?: string | number;
  isLast?: boolean;
  NewsLabel?: React.ReactNode;
  categoryName?: string;
  coverWidth?: number;
  coverHeight?: number;
  Tags?: React.ReactNode;
  contentHeight?: number | string;
  contentMargin?: string;
  url?: string;
  gtmEventType?: AsideNewsGtmEventType;
  gtmEvent?: GtmEventType;
};

const Layout = styled.div<{
  isLast?: boolean;
}>`
  list-style: none;
  border-bottom: 1px solid #e2e8f1;
  border-width: ${({ isLast }) => (isLast ? 0 : '1px')};
  padding: 16px 0;
  width: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.a<{
  contentHeight?: number | string;
  margin?: string;
}>`
  display: flex;
  align-items: center;
  height: ${({ contentHeight }) => (contentHeight ? contentHeight : 53)}px;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : '0 0 12px 0 ')};

  :visited {
    p {
      color: rgba(115, 115, 115, 0.5);
      opacity: 0.5;
    }
  }
`;

const Title = styled.p<{ fontSize?: number; lineHeight?: string | number }>`
  font-size: ${({ fontSize }) => `${fontSize}px` || '24px'};
  line-height: ${({ lineHeight }) => `${typeof lineHeight === 'number' ? lineHeight : `${lineHeight}px`}` || 1.55};
  letter-spacing: 0.5px;
  color: #383838;
  text-align: left;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    color: #e03f19;
  }
`;

const NewsImage = styled.img<{
  width?: number | string;
  height?: number | string;
}>`
  width: ${({ width }) => (width ? width : 0)}px;
  height: ${({ height }) => (height ? height : 0)}px;
  border-radius: 4px;
  object-fit: cover;
  margin-left: 8px;
  border-radius: 4px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #515c69;

  & > a {
    margin-right: 12px;
  }

  time {
    font-size: 13px;
    color: #848d97;
    letter-spacing: 0.5px;
  }

  label {
    display: block;
    font-size: 13px;
    position: relative;

    &::before {
      content: '•';
      margin: 0 5px;
      color: #e2e8f1;
    }
  }

  @media screen and (max-width: ${SCREEN_SIZE.MOBILE}px) {
    margin-bottom: 15px;
  }
`;

const NewsTheme = ({
  title = '--',
  newsId,
  coverSrc,
  publishAt = '--',
  fontSize,
  lineHeight,
  isLast,
  NewsLabel = null,
  categoryName,
  coverWidth,
  coverHeight,
  Tags = null,
  contentHeight,
  contentMargin,
  url = '',
  gtmEventType,
  gtmEvent
}: NewsThemeProps) => {
  const onClick = () => {
    if (gtmEvent) {
      sendGTM(gtmEvent);
      return;
    }
    if (gtmEventType) {
      // 只有新聞列表需要帶 label
      sendGTM({
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
        [GTMEventKey.SECTION]: ASIDE_NEWS_GTM_SECTION[gtmEventType],
        [GTMEventKey.CLICK_ITEM]: `${newsId}_${title}`,
        [GTMEventKey.LABEL]:
          gtmEventType === AsideNewsGtmEventType.NEWS_LIST ? GTMConstants.SECTION_PLACEHOLDER : undefined
      });
    }
  };

  return (
    <Layout key={`aside_news_${newsId}`} isLast={isLast} title={title}>
      <LabelWrapper>
        {NewsLabel}
        {publishAt && <time>{`${publishAt}`}</time>}
        {categoryName && (
          <>
            <span />
            <label>{categoryName}</label>
          </>
        )}
      </LabelWrapper>
      <TitleWrapper contentHeight={contentHeight} href={url} margin={contentMargin} onClick={onClick} title={title}>
        <Title className='news-title' fontSize={fontSize} lineHeight={lineHeight} title={title}>
          {title}
        </Title>
        {coverSrc && (
          <NewsImage alt='news cover' title={title} src={coverSrc} width={coverWidth} height={coverHeight} />
        )}
      </TitleWrapper>
      {Tags}
    </Layout>
  );
};

export default memo(NewsTheme);
