/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const NEWS_24H_NEEDED = 4;

const Container = styled.section`
  width: 100%;
  margin-top: 35px;
  margin-bottom: 24px;
  font-size: 14px;
  height: 470px;
`;

const ContentWrapper = styled.div`
  background-color: white;
  padding: 16px 12px;
  margin-top: 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  height: 420px;
`;

const NewsWrapper = styled.div`
  flex: 1;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const AnueLectureSkeleton = () => {
  return (
    <Container>
      <Skeleton height={38} borderRadius={6} />
      <ContentWrapper>
        <Skeleton height={22} borderRadius={6} />
        {Array(NEWS_24H_NEEDED)
          .fill('loading')
          .map((e, i) => {
            return (
              <NewsWrapper key={`${e}-${i}`}>
                <Skeleton height={70} borderRadius={6} />
              </NewsWrapper>
            );
          })}
      </ContentWrapper>
    </Container>
  );
};

export default AnueLectureSkeleton;
