/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CHART_NUMBER = 4;

const Container = styled.section`
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 8px 8px 8px;
  background-color: white;
`;

const ChartContainer = styled.div`
  width: calc(50% - 16px);
  margin: 8px;
`;

const ChartQSkeleton = () => {
  return (
    <Container>
      <Skeleton height={38} borderRadius={6} />
      <ContentWrapper>
        {Array(CHART_NUMBER)
          .fill('loading')
          .map((e, i) => {
            return (
              <ChartContainer key={`${e}-${i}`}>
                <Skeleton height={143} borderRadius={6} />
              </ChartContainer>
            );
          })}
      </ContentWrapper>
    </Container>
  );
};

export default ChartQSkeleton;
