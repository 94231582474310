/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeletion from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const BreadBrumb = styled.section`
  height: 20px;
  margin: 16px 0;
  width: 120px;
`;

const MainContainer = styled.main`
  background-color: white;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 65, 143, 0.1);
`;

const Title = styled.section`
  margin-bottom: 24px;
`;

const Author = styled.section`
  height: 20px;
  margin-bottom: 16px;
  width: 30%;
`;

const ImageSkeleton = styled.section`
  height: 440px;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50px, -54px);
  }
`;

const NewsDetailPage = () => {
  return (
    <Container>
      <BreadBrumb>
        <Skeletion />
      </BreadBrumb>
      <MainContainer>
        <Title>
          <Skeletion count={2} height={42} />
        </Title>
        <Author>
          <Skeletion />
        </Author>
        <ImageSkeleton>
          <Skeletion height={440} />
          <img
            alt="empty-skeleton"
            src="/assets/icons/skeleton/empty-image.svg"
            width={109}
            height={108}
          />
        </ImageSkeleton>
      </MainContainer>
    </Container>
  );
};

export default NewsDetailPage;
