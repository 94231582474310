/* eslint-disable import/no-extraneous-dependencies */
// TODO: 拔掉，直接用純 javascript 的 Date
import dayjs from 'dayjs';

const MS = {
  MINUTE: 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 60 * 60 * 24 * 1000
};

type DateFormat = 'YYYYMMDD' | 'YYYYMMDDHHMM' | 'MMDDHHMM' | 'MMDD' | 'HHMM';

/**
 * @description ms -> s
 * @param timestamp ms {number}
 * @returns number
 */
export const toSecond = (timestamp: number) => {
  return Math.floor(timestamp / 1000);
};

/**
 * @param n {number}
 * @returns string
 * @description 針對小於 10 的數字，前方補 0
 */
export const fillZero = (n: number) => (n < 10 ? `0${n}` : `${n}`);

/**
 *
 * @param timestamp 秒 {number}
 * @param format  'YYYYMMDD' | 'YYYYMMDDHHMM' | 'MMDDHHMM' | 'MMDD' | 'HHMM' {DateFormat}
 * @description formatTime(1697101433, 'MMDD') -> 10-12
 * @returns string
 */
export const formatTime = (timestamp: number, format: DateFormat, sep = '-'): string => {
  if (!timestamp) return '';

  const offset = 8 * 60 * 60 * 1000;
  const dateISO = new Date(timestamp * 1000 + offset).toISOString();
  const [date, timeISO] = dateISO.split('T');
  const time = timeISO.slice(0, 5);
  const [YYYY, MM, DD] = date.split('-');

  if (format === 'YYYYMMDDHHMM') return `${date} ${time}`;
  if (format === 'YYYYMMDD') return [YYYY, MM, DD].join(sep);
  if (format === 'MMDDHHMM') return `${MM}${sep}${DD} ${time}`;
  if (format === 'MMDD') return `${MM}${sep}${DD}`;
  if (format === 'HHMM') return time;

  return date;
};

/**
 * @param timestamp 秒 {number}
 * @returns timestamp 秒 {number}
 * @description 回傳同一天00:00:00的timestamp 秒
 */
export const getDateStart = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  return toSecond(startOfDay.getTime());
};

/**
 * @param timestamp ms {number}
 * @returns boolean
 */
export const isToday = (timestamp: number) => {
  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  return timestamp >= startOfDay.getTime();
};

/**
 * @param timestamp 秒 {number}
 * @description 同一天回傳HH:MM, 否則回傳MM-DD
 */
export const sameDayShowTime = (timestamp: number) => {
  if (isToday(timestamp * 1000)) {
    return formatTime(timestamp, 'HHMM');
  }

  return formatTime(timestamp, 'MMDD');
};

export const timeDisplayFactory = (timestampInMs: number, now: number) => {
  if (!timestampInMs) {
    return '';
  }

  const d = new Date(timestampInMs);
  const nowDate = new Date(now);
  const todayStartInTimestamp = nowDate.setHours(0, 0, 0, 0);
  const todayEndInTimestamp = nowDate.setHours(23, 59, 59, 999);

  // same day
  if (timestampInMs >= todayStartInTimestamp && timestampInMs <= todayEndInTimestamp) {
    return `${fillZero(d.getHours())}:${fillZero(d.getMinutes())}`;
  }

  // same year
  if (nowDate.getFullYear() === d.getFullYear()) {
    return `${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`;
  }

  // else
  return `${d.getFullYear()}/${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`;
};

/**
 * 鉅亨號貼文時間
 * @param timestampInMs
 * @returns
 */
export const postTimeDisplayFactory = (timestampInMs: number, now: number = Date.now()) => {
  if (!timestampInMs) {
    return {};
  }

  const d = new Date(timestampInMs);
  const dayTime = d?.getTime();
  const nowDate = new Date(now);
  const nowDateTime = nowDate?.getTime();

  const diff = nowDateTime - dayTime;
  const todayStartInTimestamp = new Date(nowDate)?.setHours(0, 0, 0, 0);
  const tomorrowStartInTimestamp = todayStartInTimestamp + MS.DAY;

  // < 1 min: 剛剛
  if (diff < MS.MINUTE) {
    return {
      display: '剛剛',
      delay: MS.MINUTE - diff
    };
  }

  // > 1 min: %s分鐘
  if (diff < MS.HOUR) {
    return {
      display: `${Math.floor(diff / MS.MINUTE)}分鐘前`,
      delay: MS.HOUR - diff
    };
  }

  // 當日
  if (todayStartInTimestamp < dayTime) {
    return {
      display: `${Math.floor(diff / MS.HOUR)}小時前`,
      delay: tomorrowStartInTimestamp - nowDateTime
    };
  }

  // 昨天: 昨天 HH:mm
  if (dayTime > todayStartInTimestamp - MS.DAY) {
    return {
      display: `昨天 ${fillZero(d.getHours())}:${fillZero(d.getMinutes())}`,
      delay: tomorrowStartInTimestamp - nowDateTime
    };
  }

  // 前天: MM/dd HH:mm
  if (dayTime > todayStartInTimestamp - MS.DAY * 2) {
    return {
      display: `前天 ${fillZero(d.getHours())}:${fillZero(d.getMinutes())}`,
      delay: tomorrowStartInTimestamp - nowDateTime
    };
  }

  // else: yyyy/MM/dd
  return {
    display: `${d.getFullYear()}/${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`,
    delay: 0
  };
};

export default function getDate(input: number) {
  const date = new Date(input || -1);
  const parsed = {
    valid: input,
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    date: date.getDate(),
    day: date.getDay(),
    hour: date.getHours(),
    minute: date.getMinutes()
  };

  return parsed;
}

export const getStartEndData = (day = 7) => {
  const today = Date.now();
  const todayTimestamp = Math.floor(today);
  const intervalTime = day * 60 * 60 * 24 * 1000;
  const lastWeekTimestamp = todayTimestamp - intervalTime;
  const todayObj = getDate(todayTimestamp);
  const lastWeekObj = getDate(lastWeekTimestamp);

  return {
    startAt: `${lastWeekObj.year}-${lastWeekObj.month}-${lastWeekObj.date}`,
    endAt: `${todayObj.year}-${todayObj.month}-${todayObj.date}`
  };
};

export const getMonthDaysFromPHPTimestamp = (ts: number) => {
  const d = new Date(ts * 1000);
  let day = '';

  switch (d.getDay()) {
    case 0:
      day = '(日)';
      break;
    case 1:
      day = '(一)';
      break;
    case 2:
      day = '(二)';
      break;
    case 3:
      day = '(三)';
      break;
    case 4:
      day = '(四)';
      break;
    case 5:
      day = '(五)';
      break;
    case 6:
      day = '(六)';
      break;
    default:
      break;
  }

  return {
    common: `${fillZero(d.getFullYear())}/${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`,
    noYear: `${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`,
    day
  };
};

export const shortISOString = (ms: number) => {
  if (!ms) {
    return '';
  }

  return dayjs(ms).format();
};

export const timeFormat = (ms: number, format = 'YYYY/MM/DD') => {
  return dayjs(ms).format(format);
};

export const getYearMonthDateFromPHPTimestamp = (ts: number) => {
  const d = new Date(ts * 1000);

  return `${d.getFullYear()}/${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`;
};

export const getHourMinuteFromPHPTimestamp = (ts: number) => {
  if (!ts) return null;

  const date = new Date(ts * 1000);

  return `${fillZero(date.getHours())}:${fillZero(date.getMinutes())}`;
};

/**
 * @param timestamp  ms {number}
 * @returns string
 * 一分鐘內: "剛剛"
 * 一小時內: "XX分鐘"
 * 一天內: "XX小時"
 * 其他: "MMDD"
 */
export const postTime = (timestamp: number) => {
  if (!timestamp) return '';

  const now = Date.now();
  const diff = now - timestamp;

  if (diff < MS.MINUTE) return '剛剛';
  if (diff < MS.HOUR) return `${Math.floor(diff / (1000 * 60))}分鐘`;
  if (diff < MS.DAY) return `${Math.floor(diff / (1000 * 60 * 60))}小時`;

  return formatTime(timestamp / 1000, 'MMDD');
};

/**
 * 美股開盤時間: 周一至周五，晚上9：00~隔日早上8：00
 * 特別留意星期一和星期五
 *  - 星期一是晚上 21 點開始，早上時段不算
 *  - 星期六是只到早上 8 點, 晚上不算
 * @param {string} [date = ''] - 日期時間, ex: 2021/10/25 21:05:00
 */
export const checkIsUsStockOpen = (date = '') => {
  const d = date.length > 0 ? new Date(date) : new Date();
  const day = d.getDay();
  const hour = +d.getHours();
  const ussStockOpenDays = [1, 2, 3, 4, 5, 6];
  const otherUssStockOpenDays = ussStockOpenDays.slice(1, ussStockOpenDays.length - 1);
  const ussStockOpenTime = [21, 8];

  let result = false;

  if (ussStockOpenDays.includes(day)) {
    // 星期一
    if (day === 1 && hour >= ussStockOpenTime[0]) {
      result = true;
      // 星期六
    } else if (day === 6 && hour < ussStockOpenTime[1]) {
      result = true;
      // 星期二到星期五
    } else if (otherUssStockOpenDays.includes(day)) {
      result = hour >= ussStockOpenTime[0] || hour < ussStockOpenTime[1];
    }
  }

  return result;
};

/**
 * 指數顯示規則
 * @description
 * 9:00~16:00 顯示 台灣加權、台灣櫃買、恆生指數、上證指數
   16:00-21:30 顯示NASDAQ期貨、台指期、英、法
	 21:30 顯示 道瓊指數、NASDAQ、S&P 500、費城半導體
 * @returns 'tw', 'us', 'uk'
 */
export enum IndexChartType {
  TW = 'tw',
  US = 'us',
  UK = 'uk'
}

export const getIndexChartType = () => {
  const now = new Date();
  const hours = now.getHours();
  const mins = now.getMinutes();

  if (hours >= 9 && hours < 16) {
    return IndexChartType.TW;
  }

  if ((hours >= 16 && hours < 21) || (hours === 21 && mins < 30)) {
    return IndexChartType.UK;
  }

  return IndexChartType.US;
};

export const getTodayMidnightTimestamp = () => {
  const now = Date.now();
  return now - (now % (24 * 60 * 60 * 1000));
};

/**
 * @param timestamp ms {number}
 * @description 回傳X days前的timestamp (ms)
 */
export const daysAgo = (timestamp: number, n: number) => {
  return timestamp - n * MS.DAY;
};

/**
 * @param enhanceDays 增加天數 {number}
 * @param initialDates 初始日期物件 {Date}
 * @description 回傳當下時間增加天數的日期物件
 */
export const addDays = (enhanceDays: number = 0, initialDates?: Date) => {
  if (!initialDates) initialDates = new Date();
  const tenDaysAgo = new Date(initialDates);

  return new Date(tenDaysAgo.setDate(initialDates.getDate() + enhanceDays));
};

/**
 * @param enhanceMouths 增加月份 {number}
 * @param initialDates 初始日期物件 注意是getMonth是0 - 11 {Date}
 * @description 回傳當下時間增加天數的日期物件
 */
export const addMouths = (enhanceMouths: number = 0, initialDates?: Date) => {
  if (!initialDates) initialDates = new Date();
  const tenDaysAgo = new Date(initialDates);

  return new Date(tenDaysAgo.setMonth(initialDates.getMonth() + enhanceMouths));
};

/**
 * @param enhanceYears 增加年份 {number}
 * @param initialDates 初始日期物件 {Date}
 * @description 回傳當下時間增加月份的日期物件
 */
export const addYears = (enhanceYears: number = 0, initialDates?: Date) => {
  if (!initialDates) initialDates = new Date();
  const yearsAgo = new Date(initialDates);

  return new Date(yearsAgo.setFullYear(initialDates.getFullYear() + enhanceYears));
};
