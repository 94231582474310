/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';

type NewsThemeProps = {
  title: string;
  newsId: number;
  coverSrc?: string;
  publishAt: number | string;
  target?: '_self' | '_blank';
  fontSize?: number;
  lineHeight?: string | number;
  isLast?: boolean;
  NewsLabel?: React.ReactNode;
  categoryName?: string;
  coverWidth?: number;
  coverHeight?: number;
  Tags?: React.ReactNode;
};

const ListContainerBase = styled.div<{
  isLast?: boolean;
  coverWidth?: number;
  coverHeight?: number;
}>`
  list-style: none;
  border-bottom: 1px solid #e2e8f1;
  border-width: ${({ isLast }) => (isLast ? 0 : '1px')};
  padding: 17px 0;
  width: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;

  time {
    font-size: 13px;
    color: #848d97;
    letter-spacing: 0.54px;
    line-height: 1.08;
    height: 14px;
  }

  label {
    display: block;
    height: 13px;
    font-size: 13px;
    line-height: 1;
    letter-spacing: 0.54px;
    color: #515c69;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #e2e8f1;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  img {
    width: ${({ coverWidth }) => (coverWidth ? coverWidth : 0)}px;
    height: ${({ coverHeight }) => (coverHeight ? coverHeight : 0)}px;
    border-radius: 4px;
    object-fit: cover;
    margin-left: 4px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.p<{ fontSize?: number; lineHeight?: string | number }>`
  font-size: ${({ fontSize }) => `${fontSize}px` || '24px'};
  line-height: ${({ lineHeight }) => `${lineHeight}px` || 1.75};
  letter-spacing: 0.5px;
  color: #383838;
  text-align: left;
  flex: 1;

  :hover {
    color: #e03f19;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
`;

const NewsTheme = ({
  title,
  newsId,
  coverSrc,
  publishAt,
  fontSize,
  lineHeight,
  isLast,
  NewsLabel = null,
  categoryName,
  coverWidth,
  coverHeight,
  Tags = null,
}: NewsThemeProps) => {
  return (
    <ListContainerBase
      key={newsId}
      isLast={isLast}
      coverWidth={coverWidth}
      coverHeight={coverHeight}
    >
      <LabelWrapper>
        {NewsLabel}
        <time>{`${publishAt}前`}</time>
        <label>{categoryName}</label>
      </LabelWrapper>
      <TitleWrapper>
        <Title fontSize={fontSize} lineHeight={lineHeight}>
          {title}
        </Title>
        {coverSrc && (
          <img
            alt="news cover"
            src={coverSrc}
            width={coverWidth}
            height={coverHeight}
          />
        )}
      </TitleWrapper>
      {Tags}
    </ListContainerBase>
  );
};

export default NewsTheme;
