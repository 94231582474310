/* eslint-disable import/no-extraneous-dependencies */
'use client';

import { CustomEventName, GaActionType, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { formatTime } from '@fe-common-utils/libs/time';
import { styled } from '@linaria/react';
import Link from 'next/link';
import React, { useImperativeHandle, useRef } from 'react';
import Image from 'next/image';

export type ColumnistsType = {
  columnistsId: number;
  name: string;
  engName: string;
  title: string;
  description: string | null;
  image: {
    s: ColumnistsImageType;
    m: ColumnistsImageType;
    l: ColumnistsImageType;
    xl: ColumnistsImageType;
  };
};

type ColumnistsImageType = {
  src: string;
  width: number;
  height: number;
};

type NewsListProps = {
  title: string;
  newsId: number;
  coverSrc?: string;
  publishAt: number;
  target?: '_self' | '_blank';
  breadcrumbs?: string;
  style?: React.CSSProperties;
  Tags?: React.ReactNode;
  backgroundColor?: string;
  isLast?: boolean;
  summary?: string;
  columnists?: ColumnistsType | null;
  isCelebrity?: boolean;
};

const ListContainerBase = styled.li<{
  backgroundColor?: string;
  coverSrc?: string;
  isLast?: boolean;
  isCelebrity?: boolean;
}>`
  list-style: none;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #383838;
  display: flex;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  padding-top: ${({ isCelebrity }) => (isCelebrity ? '8px' : '0px')};
  padding-bottom: 16px;
  gap: 12px;
  position: relative;
  border-bottom: ${({ isLast }) => (isLast ? 0 : '1px solid #e2e8f1')};
  height: calc(100% - 16px);
`;

const Content = styled.div`
  text-decoration: none;
  color: #383838;
  flex: 1;
  flex-direction: column;

  :hover {
    color: black;
  }
`;

const Title = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: #383838;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;

  &:hover {
    color: #000;
  }

  &:visited {
    color: rgba(56, 56, 56, 0.7);
  }

  mark {
    color: #e03f19;
    background-color: inherit;
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    font-size: 18px;
    line-height: 26px;
    -webkit-line-clamp: 2; /* 手機版搜尋列表Card標題為2行 */
  }
`;

const Summary = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #777777;
  flex: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: 8px;

  mark {
    color: #e03f19;
    background-color: white;
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    -webkit-line-clamp: 2; /* 手機版搜尋列表Card內文為2行 */
    line-height: 22px;
  }
`;

const NewsDate = styled.time`
  color: #848d97;
  margin-right: 20px;
`;

const ContentHeader = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
`;

const Breadcrumbs = styled.span`
  color: #515c69;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 2px;
    background-color: #e2e8f1;
  }
`;

const Divide = styled.div`
  background-color: #eaeef5;
  width: 2px;
  height: 16px;
  margin: 0 8px;
`;

const Figure = styled.figure<{ src: string }>`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ccc none 50% no-repeat;
  background-size: cover;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border: solid 1px #e2e8f1;

  img {
    z-index: 1;
    height: 100%;
    display: block;
  }
`;

const ColumnistAuthorName = styled(Link)`
  margin-left: 7.5px;
  font-size: 14px;
  color: #383838;

  :hover {
    color: #54b5c6;
  }
`;

type SearchNewsProps = NewsListProps & {
  ref?: React.Ref<HTMLLIElement>;
  gtmEvent?: GaActionType;
};

const SearchNews = React.forwardRef<HTMLLIElement, SearchNewsProps>(
  (
    {
      title,
      newsId,
      coverSrc,
      publishAt,
      breadcrumbs,
      Tags,
      backgroundColor,
      isLast,
      summary = '',
      columnists,
      isCelebrity = false,
      gtmEvent
    },
    ref
  ) => {
    const localRef = useRef<HTMLLIElement>(null);
    const url = `/news/id/${newsId}`;
    const onClick = () => {
      if (gtmEvent) {
        sendGTM(gtmEvent);
      } else {
        sendGTM({
          [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
          [GTMEventKey.SECTION]: GTMConstants.SECTION_PLACEHOLDER,
          [GTMEventKey.CLICK_ITEM]: `${newsId}_${title}`
        });
      }
    };

    const {
      name: columnistsName,
      columnistsId,
      image
    } = columnists ?? { name: '', columnistsId: '', image: { s: { src: '', width: 200, height: 112 } } };

    const authorUrl = `/columnists/${columnistsId}`;
    const columnistsImg = image.s;

    useImperativeHandle(ref, () => localRef.current as HTMLLIElement);

    return (
      <ListContainerBase
        ref={localRef}
        key={newsId}
        backgroundColor={backgroundColor}
        coverSrc={coverSrc}
        isLast={isLast}
        isCelebrity={isCelebrity}
        className='search-news-container'
      >
        <Content>
          <ContentHeader className='search-content-header'>
            {isCelebrity && (
              <>
                <Figure src={columnistsImg.src}>
                  <Image
                    src={columnistsImg.src}
                    alt='columnists'
                    width={columnistsImg.width}
                    height={columnistsImg.height}
                  />
                </Figure>
                <ColumnistAuthorName href={authorUrl}>{columnistsName}</ColumnistAuthorName>
                <Divide />
              </>
            )}
            <NewsDate>{typeof publishAt === 'string' ? publishAt : formatTime(publishAt, 'MMDD')}</NewsDate>
            {breadcrumbs && <Breadcrumbs>{breadcrumbs}</Breadcrumbs>}
          </ContentHeader>
          <Link prefetch={false} href={url} scroll={false}>
            <Title title={title} dangerouslySetInnerHTML={{ __html: title }} onClick={onClick}></Title>
          </Link>
          {summary && <Summary dangerouslySetInnerHTML={{ __html: summary }} />}
          {Tags && Tags}
        </Content>
      </ListContainerBase>
    );
  }
);

export default SearchNews;
