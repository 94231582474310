import NewsSkeleton from './theme/News/News';
import News24hSkeleton from './theme/News24hSkeleton';
import NewsPopularSkeleton from './theme/NewsPopularSkeleton';
import AsideYesClubAd from './theme/AsideYesClubAd';
import NewsIssueSkeleton from './theme/NewsIssueSkeleton';
import NewsRecentTopic from './theme/NewsRecentTopic';
import NewsTwStockExpert from './theme/NewsTwStockExpert';
import LogoWallSkeleton from './theme/LogoWallSkeleton';
import AnueLectureSkeleton from './theme/AnueLecureSkeleton';
import NewsFeatureReport from './theme/NewsFeatureReport';
import SkeletonAnueService from './theme/SkeletonAnueService';
import LiTVSkeleton from './theme/LiTVSkeleton';
import NewsDetailPage from './theme/NewsDetailPage/NewsDetailPage';
import ChartQSkeleton from './theme/ChartQSkeleton';
import AsideTableSkeleton from './theme/AsideTableSkeleton';

export {
  NewsSkeleton,
  News24hSkeleton,
  NewsPopularSkeleton,
  AsideYesClubAd,
  NewsIssueSkeleton,
  NewsRecentTopic,
  NewsTwStockExpert,
  LogoWallSkeleton,
  AnueLectureSkeleton,
  NewsFeatureReport,
  SkeletonAnueService,
  LiTVSkeleton,
  NewsDetailPage,
  ChartQSkeleton,
  AsideTableSkeleton
};
export default {
  NewsSkeleton,
  News24hSkeleton,
  NewsPopularSkeleton,
  AsideYesClubAd,
  NewsIssueSkeleton,
  NewsRecentTopic,
  NewsTwStockExpert,
  LogoWallSkeleton,
  AnueLectureSkeleton,
  NewsFeatureReport,
  SkeletonAnueService,
  LiTVSkeleton,
  NewsDetailPage,
  ChartQSkeleton,
  AsideTableSkeleton
};
