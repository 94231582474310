/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-globals */

import 'anue-fe-sdk';

import { isClient } from '@fe-common-utils/libs/utils';
import Auth from 'anue-fe-sdk/Auth';
import Eventy from 'anue-fe-sdk/eventy';
import getty from 'anue-fe-sdk/getty';
import 'anue-fe-sdk/web-preset';
import { Dispatch, useEffect, useReducer } from 'react';
// import getChannel from '@fe-news/utils/client/channel';
// import { initialProfile } from '@fe-news/constants/auth/member';
const event = new Eventy();

let auth: AuthService;
let globalAuthState: unknown = {
  data: null,
  status: 'init',
  error: null
};

/**
 * 當會員狀態有任何更新時，都會透過 auth 事件發送通知
 * 更新會員認證狀態
 *
 * @param actionType AuthActionTypes
 * @param data Anue.Auth.Credentials
 * @param error unknown
 */
function syncAuthState(actionType: AuthActionTypes, data: Anue.Auth.Credentials | null = null, error?: unknown) {
  event.emit('auth', {
    type: actionType,
    data,
    error
  });
}

/**
 * 依照目前環境返還對應的會員登入頁面
 */
function getPortalUrlByHost() {
  return process.env.NEXT_PUBLIC_LOGIN_INFO_URL;
}

/**
 * 依照目前環境返還會員 API Domain
 */
function getMemberServiceUrlByHost() {
  return process.env.NEXT_PUBLIC_MEMBER_URL;
}

/**
 * 註冊監聽 auth 事件
 */
function subscribeAuthEvent(dispatcher: EventyHandler | Dispatch<IAuthAction>) {
  return event.on('auth', dispatcher);
}

/**
 * 取得會員個人資料
 */
export function getProfile(): UserProfile | null {
  if (auth) {
    const context = auth?.getCredentialContext?.();

    // [ANUE-6796]: 前端顯示用戶名稱改取nickname欄位，若會員若沒有nickname，預設顯示：鉅亨會員
    return context && context.profile
      ? {
          ...context.profile,
          nickname: context.profile.nickname || '鉅亨會員',
          phone_verified: context.profile.phone_verified,
          hao_login_id: context.profile.hao_login_id
        }
      : null;
  }

  return null;
}

/**
 * 取得會員更新後的個人資料
 */
// export async function getRenewProfile() {
//   if (auth) {
//     const profile = await auth.syncProfileFromRemote(true);

//     return profile;
//   }

//   return null;
// }

// export function getFlag() {
//   if (auth) {
//     const ctx = auth.getCredentialContext();

//     return ctx ? ctx.flag : null;
//   }

//   return null;
// }

/**
 * 透過 auth 的 onStateChange callback 監看會員登入情況
 * 登入成功的話，ctx 包含的格式會是// 透過 auth 的 onStateChange callback 監看會員登入情況
 * {
    context: {
      authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJleHAiOjE2MjUyMTI0OTMsImlhdCI6MTYyNTIxMTU5MywiZW1haWwiOiJ0ZXN0QGFudWVncm91cC5jb20udHciLCJpZGVudGl0eV9pZCI6ImFwLW5vcnRoZWFzdC0xOmExYjYxYjU1LTQ2YWItNDY0OS04YmJjLWQwNTBhOWMyZjg5NSJ9.dm7Ufq8swP4h3kKAoRzpnUc0kgQ-tyMjqkEBb3RrjHO37UkpbhmedfGCpdEBeKdUO3IkTq2SHEmqoQfBEsVaDqmJ45-Z_z8VV5zw3Sa2zPM_ZAE1OXT6Bw3E17gcUthDIBnUHnADiMxXillS_AIV-5wxKlN1-vHQcOQa4zPj_7aA3szy_HYweRGa_QqunTewItBhOr1ZnDzX1cucNvRPS3A_im4979Kpp8rc1VOMAywXSYqhz4m-IKoTh7p1QRgwbkqeJg3k6rwZKrj4VegSOYdJpHO5kYtyretB-QaPNtBw3IqlN5aVK6bIVHJRgJIRqBoDoTTmCRBLJPOMjA9vZg"
      flag: ""
      profile: { identity_id: "ap-northeast-1:a1b61b55-46ab-4649-8bbc-d050a9c2f895", name: "weww", nickname: "", email: "test@anuegroup.com.tw", gender: 0, … }
    },
    flag: ""
    status: "online"
  };
 */

let alreadyListenEvent = false; // 利用這個 flag 來避免發生重複註冊監聽

async function handleAuthStatusChange() {
  if (auth && !alreadyListenEvent) {
    alreadyListenEvent = true;

    auth.onError((error: unknown) => {
      syncAuthState('error', null, error);
    });

    const success = await auth
      .onStateChange((context: Anue.Auth.StateChangeEvent) => {
        if (context.status === 'online') {
          syncAuthState('login', context.context);
        } else {
          syncAuthState('logout', null);
        }
      })
      .consume();

    if (!success) {
      syncAuthState('logout', null);
    }
  }
}

type AuthInitProps = {
  initAuthToken?: string;
  isWebView?: boolean;
};

/**
 * 會員認證狀態初始化
 *
 * init 主要是在 `src/components/FlexHeader` 中被載入
 * 另外還有在 WebviewWrapper(`src/decorators/withWebView.tsx`) 載入
 */
export async function init({ initAuthToken, isWebView }: AuthInitProps = {}) {
  if (!auth && isClient) {
    auth = new Auth({
      token: initAuthToken,
      flag: isWebView ? 'AnueAppWebView' : '',
      isWebView
    });
    auth.host(`${getMemberServiceUrlByHost()}/member`);
  }
  if (auth && auth.setAuthToken && initAuthToken) auth.setAuthToken(initAuthToken, isWebView ? 'AnueAppWebView' : '');
  if (auth && auth.setIsInWebView && isWebView) auth.setIsInWebView(isWebView);

  await handleAuthStatusChange();
}

/**
 * An exported method which allows a module to examine if user actually logged-in
 */
export const isLogin = () => {
  if (auth) {
    return Boolean(getty(auth?.getCredentialContext(), ['profile', 'identity_id']));
  }

  return false;
};

/**
 * An exported method which allows a module to invoke login without binding the hook
 */
export const login = () => {
  if (location) {
    const { origin, pathname, search } = location;

    let aink = `${origin}${pathname}`;

    if (pathname.includes('ord_checkout') || pathname.includes('oauth')) {
      // checkout page need to add search param to aink
      aink = `${origin}${pathname}${search}`;

      location.href = `${getPortalUrlByHost()}?aink=${encodeURIComponent(aink)}`;
    } else {
      location.href = `${getPortalUrlByHost()}?aink=${encodeURIComponent(aink)}`;
    }
  }
};

/**
 * An exported method which allows a module to use logout without binding the hook
 */
export const logout = async () => {
  auth.clearCredentials();
};

function reducer(state: AuthStore, action: IAuthAction) {
  let nextState;

  switch (action.type) {
    case 'login': {
      nextState = { data: action.data, error: null, status: 'login' };
      break;
    }
    case 'logout': {
      nextState = { data: null, error: null, status: 'logout' };
      break;
    }
    case 'error': {
      nextState = { data: null, error: action.error, status: 'logout' };
      break;
    }
    case 'update': {
      nextState = { data: action.data, error: null, status: 'login' };
      break;
    }
    default: {
      nextState = state;
      break;
    }
  }

  globalAuthState = nextState;

  return nextState;
}

export function getEmail() {
  const profile = getProfile();

  return profile ? profile.email : null;
}

/**
 * Update user's email (only for those who uses Facebook without an email).
 * returns error message or `null`.
 * @param email The email that user provided
 */
// const EmailErrorMapping = {
//   4011: '該信箱已被使用',
// };

// export async function updateEmail(email: string): Promise<string | unknown> {
//   const result = await auth.updateEmail(email);

//   if (result.error) {
//     const errorStatus = getty(result, ['error', 'status_code']) as 4011;
//     const errorMsg = getty(result, ['error', 'message']);
//     const error =
//       (errorStatus && EmailErrorMapping[errorStatus]) ||
//       errorMsg ||
//       '系統異常，更新資料失敗';

//     return error;
//   }

//   syncAuthState('update', auth?.getCredentialContext());

//   return null;
// }

/**
 * 啟用 useAuth 時，會在本地端註冊監聽 auth 事件
 * 手法: 建立一個 Store，仿 Redux，去保存目前會員相關狀態和資料
 * 再從這 Store 中去查找目前會員狀態，返回對應狀態
 *
 * @return [init' | 'login' | 'logout]
 */
export default function useAuth(): UseAuth {
  const [state, dispatch] = useReducer<(state: AuthStore, action: IAuthAction) => AuthStore>(
    reducer,
    globalAuthState as AuthStore
  );

  useEffect(() => {
    // 註冊監聽
    return subscribeAuthEvent(dispatch);
  }, []);

  return [state.status];
}

/**
 * 更新會員個人資料，資料寫入 local storage
 */
// export async function setProfile(profile: unknown) {
//   if (auth) {
//     const rpcChannel = getChannel();

//     await rpcChannel?.rpcCall('storage', [
//       'set',
//       'profile',
//       JSON.stringify(profile),
//     ]);
//   }
// }

/**
 * 建立會員個人資料 Context
 */
// export const ProfileContext = createContext<UserProfile>({ ...initialProfile });

/**
 * 登入狀態改變callback
 */
export function onChange(function_: EventyHandler<Anue.Auth.StateChangeEvent>) {
  if (auth) {
    auth.onStateChange(function_);
  }
}
