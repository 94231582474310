/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const NEWS_24H_LIMIT = 4;

const Container = styled.section`
  width: 100%;
  margin: 35px 0;
  font-size: 14px;
  height: 643px;
`;

const ContentWrapper = styled.div`
  background-color: white;
  padding: 16px 12px;
  margin-top: 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  max-height: 593px;
`;

const NewsWrapper = styled.div`
  flex: 1;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 182px;
`;

const News24hSkeleton = () => {
  return (
    <Container>
      <Skeleton height={38} borderRadius={6} />
      <ContentWrapper>
        {Array(NEWS_24H_LIMIT)
          .fill('loading')
          .map((e, i) => {
            return (
              <NewsWrapper key={`${e}-${i}`}>
                <Skeleton height={27} borderRadius={6} />
                <Skeleton height={53} borderRadius={6} />
                <Skeleton height={28} />
              </NewsWrapper>
            );
          })}
      </ContentWrapper>
    </Container>
  );
};

export default News24hSkeleton;
