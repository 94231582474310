/* eslint-disable import/no-extraneous-dependencies */
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';

type NewsListProps = {
  title: string;
  newsId: number;
  coverSrc?: string;
  publishAt: number | string | null;
  target?: '_self' | '_blank';
  breadcrumbs?: string;
  style?: React.CSSProperties;
};

const ListContainerBase = styled.li`
  list-style: none;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #383838;
  line-height: 25px;
  padding: 8px 8px 8px 16px;
  width: 782px;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0, 65, 143, 0.1);
  height: 106px;
  border-radius: 6px;

  @media screen and (max-width: ${SCREEN_SIZE.MOBILE}px) {
    width: calc(100vw - 24px);
  }

  .content {
    text-decoration: none;
    color: #383838;

    @media screen and (max-width: ${SCREEN_SIZE.MOBILE}px) {
      width: 100%;
    }

    :hover {
      color: black;
    }

    p {
      font-size: 18px;
      margin: 0 0 4px 0;
    }

    .time {
      font-size: 14px;
      color: #515c69;
      display: flex;
      align-items: center;

      span {
        ::after {
          content: '·';
          margin: 0 2px;
          color: #777777;
        }
      }

      time {
        font-size: 12px;
        color: #909090;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        height: 17px;
        position: relative;
        padding-left: 16px;

        &::before {
          content: '';
          background-image: url('/assets/icons/time/clock.svg');
          position: absolute;
          left: 0;
          top: 2px;
          width: 12px;
          height: 12px;
        }
      }
    }

    img {
      border-radius: 4px;
    }
  }
`;

const NewsList = ({ title, newsId, coverSrc, publishAt, breadcrumbs, style }: NewsListProps) => {
  return (
    <div style={style} key={newsId}>
      <ListContainerBase>
        <div className='content'>
          <p>{title}</p>
          <div className='time'>
            <span>{breadcrumbs}</span>
            <time>{publishAt}</time>
          </div>
        </div>
        {coverSrc && <img alt='news cover' width={90} height={64} src={coverSrc} />}
      </ListContainerBase>
    </div>
  );
};

export default NewsList;
