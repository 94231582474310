import FetchClientFactory from './clientFactory';

interface HttpClientOptions {
  host?: string;
  headers?: Record<string, string>;
}

const HttpClient = ({ host, headers }: HttpClientOptions = { host: 'https://api.cnyes.com', headers: {} }): FetchClientFactory =>
  new FetchClientFactory(String(host), headers);

export default HttpClient;
