'use client'

import { styled } from '@linaria/react';

const Label = styled.button<{ color?: string; marginRight?: number }>`
  background: ${({ color }) => (color ? color : '#54b5c6')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}px;
  color: white;
  border-radius: 6px;
  padding: 2px 8px;
  font-size: 14px;
  line-height: 20px;
`;

export const NewsLabel = ({
  children,
  color,
  marginRight,
}: {
  children: React.ReactNode;
  color?: string;
  marginRight?: number;
}) => {
  return (
    <Label color={color} marginRight={marginRight}>
      {children}
    </Label>
  );
};

export default NewsLabel;
