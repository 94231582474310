// https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/2143387649/Mweb

export enum NewsCategory {
  ALL = 'all', // 總覽
  HEADLINE_ALL = 'headline_all', // Mobile頭條新聞列表
  // ------ 頭條 --------------------------
  HEADLINE = 'headline', // 總覽(頭條新聞)
  NEWS_24H = 'news24h', // 即時新聞
  TOP_TOPICS = 'topTopics', // 議題
  NEWS_MARCO = 'newsMarco', // 宏觀
  TRENDING = 'trending', // 人氣
  PROJECT = 'project', // 專題
  // ------ 台股 --------------------------
  TW_STOCK = 'tw_stock', // 台股(總覽)
  TW_STOCK_NEWS = 'tw_stock_news', // 台股新聞
  TW_MACRO = 'tw_macro', // 台灣政經
  TW_QUO = 'tw_quo', // 台股盤勢
  STOCK_REPORT = 'stock_report', // 專家觀點
  STOCK_TUTORIAL = 'stock_tutorial', // 投資工具
  TW_BULL = 'tw_bull', // 台股公告
  EME_BULL = 'eme_bull', // 興櫃公告
  TW_CALC = 'tw_calc', // 台股表格
  ETF = 'etf', // 台股表格
  // ------ 美股 --------------------------
  WD_STOCK = 'wd_stock', // 美股(總覽) (過去的國際股)
  WD_STOCK_ALL = 'wd_stock_all', // 美股(全部) 
  US_STOCK = 'us_stock', // 美股雷達
  WD_MACRO = 'wd_macro', // 國際政經
  EU_ASIA_STOCK = 'eu_asia_stock', // 歐亞股
  // ------ 陸港股 --------------------------
  CN_STOCK = 'cn_stock', // 陸港股(總覽)
  CN_STOCK_ALL = 'cn_stock_all', // 陸港股(全部)
  HK_STOCK = 'hk_stock', // 港股
  SH_STOCK = 'sh_stock', // A股
  HK_REPORT = 'hk_report', // 大行報告
  HK_IPO = 'hk_ipo', // 新股上市
  HK_WARRANT = 'hk_warrant', // 權證
  HK_MACRO = 'hk_macro', // 香港政經
  CN_MACRO = 'cn_macro', // 大陸政經
  // ------ 速報 --------------------------
  ANUE_LIVE = 'anue_live', // 速報 (總覽)
  TW_PRE_MARKET = 'tw_premarket', // 台股盤前
  TW_LIVE = 'tw_live', // 台股盤中
  TW_AFTER_HOURS = 'tw_afterhours', // 台股盤後
  TW_FORECAST = 'tw_forecast', // 台股預估
  US_LIVE = 'us_live', // 美股盤中
  US_FORECAST = 'us_forecast', // 美股預估
  US_LIVE_FORECATE = 'us_live,us_forecast',
  CRYPTO_LIVE = 'crypto_live', // 虛擬貨幣
  FOREX_LIVE = 'forex_live', // 外匯盤中
  TW_REVENUE = 'tw_revenue', // 營收速報
  // ------ 基金 --------------------------
  FUND = 'fund', // 基金(總覽)
  // ------ 外匯 --------------------------
  FOREX = 'forex', // 外匯(總覽)
  // ------ 期貨 --------------------------
  FUTURES = 'future', // 期貨(總覽)
  FUTURES_INDEX = 'index_futures', // 指數
  FUTURES_STOCK = 'stock_futures', // 股票
  FUTURES_ENERGY = 'energy', // 能源
  FUTURES_BOND = 'futu_bond', // 債券
  FUTURES_PRODUCE = 'futu_produce', // 農作
  FUTURES_METALS = 'precious_metals', // 黃金
  // ------ 區塊鏈 --------------------------
  CRYPTO = 'bc', // 區塊鏈(總覽)
  BC_CRYPTO = 'bc_crypto', // 幣圈
  BC_NEWS = 'bc_news', // 鏈文
  BC_TUTORIAL = 'bc_tutorial', // 新手村
  BC_LIVE = 'bc_live', // 快訊
  // ------ 房產 --------------------------
  HOUSE = 'cnyeshouse', // 房產(總覽)
  HOUSE_CN = 'cn_housenews', // 大陸房市
  HOUSE_HK = 'hk_housenews', // 香港房市
  HOUSE_TW = 'tw_housenews', // 台灣房市
  HOUSE_WD = 'wd_housenews', // 海外房市
  // ------ 理財 --------------------------
  TW_MONEY = 'tw_money', // 理財(總覽)
  BANK = 'bank', // 銀行
  TW_INSURANCE = 'tw_insurance', // 保險
  SPENDING = 'spending', // 消費
  FINANCIAL = 'financial', // 其他
  // ------ 科技 --------------------------
  TECH = 'tech', // 科技(總覽)
  // ------ 新視界 --------------------------
  CELEBRITY_AREA = 'celebrity_area', // 新視界(總覽)
  COLUMNISTS = 'columnists', // 名家總覽
  COLUMNIST = 'columnist', // 各個名家
  // ------ 研報 --------------------------
  REPORT = 'report', // 研究報告
  REPORT_FUND = 'fund_comment', // 基金研報
  REPORT_TW = 'tw_report', // 台股研報
  REPORT_GLOBAL = 'global_report', // 國際股研報
  REPORT_FOREX = 'fx_report', // 外匯研報
  // ------ 雜誌 --------------------------
  MAG = 'mag', // 雜誌(總覽)
  // ------ 自定義 (不存在資料庫中) --------------
  TOPICS = 'topics', // 專題
  MY_TRACKING = 'my_tracking', // 我的追蹤
  MY_STOCKS = 'my_stocks', // 自選股
  // ------ 公告 ------
  ANNOUNCEMENT = 'announcement', // 公告
  // ----- 一手情報 -----
  ADVERTORIAL = 'advertorial', //一手情報
  // ------ 熱門時事 ------
  POP_TOPIC = 'poptopic', // 熱門時事
  // ------ 我的新聞 ------
  MY_NEWS = 'mynews',
  MY_TRACK = 'mytrack',
  MY_TAG = 'mytag', // 追蹤標籤
  MY_POP_TOPIC = 'mypoptopic', // 追蹤時事
  PORTFOLIOS = 'portfolios', // 自選清單
  MY_COMMENT = 'my_comment', // 我的留言
  NEWS_COLLECT = 'news_collect', // 我的收藏
  RANKING_LIST = 'rankinglist' // 熱門排行
}
