/* eslint-disable import/no-extraneous-dependencies */
'use client';

import { styled } from '@linaria/react';
import cx from 'classnames';

type ItemValue = number | string;

const Tabs = styled.ul`
  display: flex;
  align-items: center;
`;

const Tab = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 49px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #515c69;
  background-color: #fff;
  position: relative;
  cursor: pointer;

  &.active {
    font-weight: 500;
    color: #e03f19;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 2px;
      opacity: 0.9;
      box-shadow: 0 2px 6px 0 rgba(224, 63, 25, 0.5);
      background-color: #e03f19;
      z-index: 2;
    }
  }
`;

export const MobileTabs = ({
  active,
  items,
  onClick,
  className
}: {
  active: number;
  items: { label: string; value?: ItemValue }[];
  onClick?: (index: number, value?: ItemValue) => void;
  className?: string;
}) => {
  const handleClick = (index: number, value?: ItemValue) => () => {
    onClick?.(index, value);
  };

  return (
    <Tabs className={className}>
      {items?.map(({ label, value }, index) => (
        <Tab key={label} className={cx({ active: active === index })} onClick={handleClick(index, value)}>
          {label}
        </Tab>
      ))}
    </Tabs>
  );
};

export default MobileTabs;
