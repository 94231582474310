/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Container = styled.section`
  width: 100%;
  font-size: 14px;
`;

const ContentWrapper = styled.div<{ height: number }>`
  background-color: white;
  padding: 12px;
  margin-top: 12px;
  min-height: ${({ height }) => height ?? 0}px;
`;

const AsideTableSkeleton = ({ height = 0 }: { height?: number }) => {
  return (
    <Container>
      <Skeleton height={38} borderRadius={6} />
      <ContentWrapper height={height}>
        <Skeleton height={height} />
      </ContentWrapper>
    </Container>
  );
};

export default AsideTableSkeleton;
