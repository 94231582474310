'use client';
/* eslint-disable import/no-extraneous-dependencies */
import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import Button, { NewsTagGtmEventType } from '@fe-common-ui/Button';
import Quote from '@fe-common-ui/Quote';
import { styled } from '@linaria/react';
import { FC } from 'react';

export enum QuoteGtmEventType {
  NEWS_24H = 'news24h',
  NEWS_ARTICLE_INTERESTED = 'newsArticleInterested',
  NEWS_LIST = 'newsList',
  NEWS_CATEGORY_POP_TOPIC = 'newsCategoryPopTopic',
  NEWS_CATEGORY_POP_TOPIC_ID = 'newsCategoryPopTopicId',
  NEWS_CATEGORY_MY_POP_TOPIC = 'newsCategoryMyPopTopic'
}

const QUOTE_GTM_SECTION = {
  news24h: `${GTMConstants.SECTION_PLACEHOLDER}_主編精選`,
  newsArticleInterested: `${GTMConstants.SECTION_PLACEHOLDER}_可能感興趣`,
  newsList: GTMConstants.SECTION_PLACEHOLDER,
  newsCategoryPopTopic: 'News_Category_時事',
  newsCategoryPopTopicId: 'News_Category_時事內頁',
  newsCategoryMyPopTopic: 'News_Category_我的追蹤'
};

type OtherProducts = {
  name?: string;
  ratio?: number;
  marketLink?: string;
  symbol?: string;
};
interface TagsGroupProps {
  focusTag?: string;
  newsId?: string;
  tags?: string[];
  otherProduct?: OtherProducts[];
  isFilterByMarket?: boolean;
  gtmEventNewsTagType?: NewsTagGtmEventType;
  gtmEventQuoteType?: QuoteGtmEventType;
  backgroundColor?: string;
  fontColor?: string;
  height?: number;
  isCarousel?: boolean;
  isPopTopic?: boolean;
  isPopTopicNews?: boolean;
  isPopTopicNewsId?: boolean;
}

const TagsWrapper = styled.div<{ height?: number }>`
  display: flex;
  align-items: center;
  gap: 8px;
  height: ${({ height }) => height || 26}px;
  flex-wrap: wrap;
  overflow: hidden;
`;

const MarketButton = styled.a<{ isPopTopic?: boolean }>`
  padding: 6px 8px;
  border-radius: 6px;
  background-color: rgba(226, 232, 241, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .title {
    font-size: ${({ isPopTopic }) => (isPopTopic ? '15px' : '14px')};
    line-height: 1;
    letter-spacing: 0.5px;
    color: #515c69;
    max-width: 87px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    .title {
      color: #1c1e2f;
    }
  }

  .ratio {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.5px;
    color: #e03f19;
    white-space: nowrap;
  }
`;

/**
 * Tag會在右邊側邊欄空間不夠放，先以有商品標籤最多放兩個，沒有最多三個。
 */
export const TagsGroup: FC<TagsGroupProps> = ({
  focusTag = '',
  newsId = '',
  tags = [],
  otherProduct = [],
  isFilterByMarket = false,
  gtmEventNewsTagType,
  gtmEventQuoteType,
  backgroundColor,
  fontColor,
  height = 28,
  isCarousel = false,
  isPopTopic = false,
  isPopTopicNews = false,
  isPopTopicNewsId = false
}) => {
  const activeTag = focusTag.split(',');
  const filterActiveTag = activeTag.filter(tag => tags.includes(tag));
  let displayTags = Array.from(new Set([...filterActiveTag, ...tags]));

  if (isFilterByMarket) {
    const tagsAmountByMarket = otherProduct.length ? 2 : 3;
    displayTags = tags.slice(0, tagsAmountByMarket);
  }
  const onClick = (symbolId: string, symbolName: string) => {
    if (gtmEventQuoteType) {
      sendGTM({
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_QUOTE,
        [GTMEventKey.SECTION]: QUOTE_GTM_SECTION[gtmEventQuoteType],
        [GTMEventKey.CLICK_ITEM]: isCarousel
          ? `置頂時事_${symbolId}_${symbolName}`
          : QuoteGtmEventType.NEWS_CATEGORY_MY_POP_TOPIC === gtmEventQuoteType
            ? `追蹤的時事＿${symbolId}_${symbolName}`
            : `${symbolId}_${symbolName}`
      });
    }
  };

  return (
    <TagsWrapper height={height}>
      {otherProduct?.map(({ name = '', ratio = 0, marketLink = '', symbol = '' }, index) => {
        return (
          <MarketButton
            key={`${name}-${index}`}
            href={marketLink}
            onClick={() => onClick(symbol, name)}
            className='market-button'
            title={name}
            isPopTopic={isPopTopic}
          >
            <p className='title'>{name}</p>
            {!isPopTopic && (
              <Quote change={ratio} fontSize={15}>
                {ratio && ratio?.toFixed(2)}%
              </Quote>
            )}
          </MarketButton>
        );
      })}
      {displayTags.length > 0 &&
        displayTags.map((tag, index) => {
          const activeTag = focusTag.split(',').includes(tag);

          return (
            <Button.NewsTag
              key={`${newsId}_${tag}_${index}`}
              padding='5px 10px'
              link={`/tag/${tag}`}
              gtmEventType={gtmEventNewsTagType}
              tag={tag}
              activeTag={activeTag}
              backgroundColor={backgroundColor}
              fontColor={fontColor}
              isPopTopic={isPopTopic}
              isPopTopicNews={isPopTopicNews}
              isPopTopicNewsId={isPopTopicNewsId}
            />
          );
        })}
    </TagsWrapper>
  );
};

export default TagsGroup;
