'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { styled } from '@linaria/react';

const FOLDER_BUTTON_ACTIVE_WORDING = '收起';
const FOLDER_BUTTON_DEACTIVE_WORDING = '更多';

type FolderProps = {
  active?: boolean;
  onClick?: () => void;
  theme?: string;
  order?: number;
  hide?: boolean;
  gtmEvent?: {
    [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON;
    [GTMEventKey.SECTION]: string;
    [GTMEventKey.CLICK_ITEM]: typeof FOLDER_BUTTON_ACTIVE_WORDING | typeof FOLDER_BUTTON_DEACTIVE_WORDING
  }
};

const ArrowIcon = styled.img<{ active?: boolean }>`
  transform: ${({ active }) => (active ? 'rotate(180deg)' : 0)};
`;

const BaseButton = styled.button<{ order?: number; hide?: boolean }>`
  align-items: center;
  font-size: 15px;
  padding: 6px 5px 5px 9px;
  border-radius: 6px;
  background-color: rgba(226, 232, 241, 0.6);
  color: #515c69;
  cursor: pointer;
  order: ${({ order }) => (order ? order : 'unset')};
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
`;

const WhiteButton = styled(BaseButton)`
  background-color: white;
  border: 1px solid #e2e8f1;

  &:hover {
    background-color: #e2e8f199;
  }
`;

const Folder = ({ active, onClick, theme = 'white', order, hide, gtmEvent }: FolderProps) => {
  const ThemeButton = theme === 'white' ? WhiteButton : BaseButton;

  const handleOnClick = () => {
    if(gtmEvent) sendGTM(gtmEvent);
    if(onClick) onClick();
  }

  return (
    <ThemeButton onClick={handleOnClick} order={order} hide={hide}>
      {active ? FOLDER_BUTTON_ACTIVE_WORDING : FOLDER_BUTTON_DEACTIVE_WORDING}
      <ArrowIcon
        alt="arrow-icon"
        src="/assets/icons/arrows/arrow-down.svg"
        width={20}
        height={20}
        active={active}
      />
    </ThemeButton>
  );
};

export default Folder;
