export enum MyTrackSelectDate {
  RECENT_ONE_WEEK = '近一週',
  RECENT_ONE_MONTH = '近一月',
  RECENT_THREE_MONTH = '近三月',
  RECENT_HALF_YEAR = '近半年',
  RECENT_ONE_YEAR = '近一年'
}

type ResponseRankingItem<T extends RankingProductType> = T extends 'TAGS'
  ? { tag: string; cnt: number }
  : { product_id: string; cnt: number };

export type ResponseRankingListType<T extends RankingProductType> = {
  items: ResponseRankingItem<T>[];
  message: string;
  status_code: number;
};

export type ReturnRankingListType = {
  items: { productId: string; cnt: number }[];
  message: string;
  statusCode: number;
};

export type ResponseTagsBySearchType = {
  items: { key: string; doc_count: number }[];
  message: string;
  statusCode: number;
};

export type ReturnTagsBySearchType = {
  tags: { key: string; docCount: number }[];
};

export enum RankingProductType {
  NEWS = 'NEWS',
  POPTOPIC = 'POPTOPIC',
  TAGS = 'TAGS',
  TAG = 'TAG'
}

export enum MyCollectionProductType {
  NEWS = 'NEWS',
  POPTOPIC = 'POPTOPIC'
}

export type ResponseMyCollectionType = {
  items: {
    data: { identity_id: string; product_type: MyCollectionProductType; product_id: number; sort: number }[];
    count: number;
  };
  message: string;
  status_code: number;
};

export type ReturnMyCollectionType = {
  items: { identityId: string; productType: MyCollectionProductType; productId: number; sort: number }[];
  count: number;
};

export type ResponsePortfoliosType = {
  items: {
    data: News[];
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    next_page_url: string | null;
    prev_page_url: string | null;
    from: number;
    to: number;
  };
  message: string;
  statusCode: number;
};

export type ReturnPortfoliosType = PageData<News[]>;

export type AsideRankingListOption = {
  tab: number;
  subTab: number;
};

export const MARKET_SELECT_LIST = [
  { id: 0, name: '全部市場' },
  { id: 826, name: '台股' },
  { id: 830, name: '美股' },
  { id: 835, name: 'A股' },
  { id: 836, name: '港股' },
  { id: 838, name: '外匯' },
  { id: 873, name: '虛擬貨幣' }
];

export type HotCollectionCardProps = {
  id: number;
  hotRate: number;
  index: number;
  isRankingList: boolean;
};

export type HotPopTopicCardProps = {
  hotRank: number;
  id: string;
};

export type AsidePopTopicCardProps = {
  id: number;
  isLastChild: boolean;
};

export const LIMIT_NEWS_COLLECTION_COUNT = 30;

export const LIMIT_POP_TOPIC_COUNT = 10;
