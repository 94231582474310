interface RequestHeaders {
  [key: string]: string;
}

export interface SearchParams {
  [key: string]: string | number | Record<string, unknown> | undefined;
}

export default class ClientFactory {
  private baseURL: string;

  private headers: RequestHeaders | Headers;

  constructor(baseURL: string, headers: RequestHeaders = {}) {
    this.baseURL = baseURL;
    this.headers = new Headers({
      'Content-Type': 'application/json',
      ...headers
    });
  }

  private fetchWrapper(url: string, options: RequestInit) {
    const apiUrl = `${this.baseURL}${url}`;

    return fetch(apiUrl, {
      headers: this.headers,
      ...options
    }).then(response => {
      if (!response.ok)
        throw new Error(
          JSON.stringify({
            url: apiUrl,
            code: response.status,
            text: response.statusText
          })
        );
      return response.json();
    });
  }

  get(url: string, params: SearchParams = {}, options: RequestInit = {}) {
    const searchParams = new URLSearchParams();
    const paramsKeys = Object.keys(params);
    const connectStr = paramsKeys.length > 0 ? '?' : '';

    paramsKeys.forEach(key => searchParams.append(key, String(params[key])));
    const queryString = searchParams.toString();

    return this.fetchWrapper(`${url}${connectStr}${queryString}`, {
      method: 'GET',
      ...options
    });
  }

  post(url: string, body: unknown, options: RequestInit = {}) {
    return this.fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify(body),
      ...options
    });
  }
}
