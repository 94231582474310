/**
 * enabledDefaultHeight: 是否要有廣告預設高度(如果使用預設高度，在顯示多組廣告尺寸時會有問題)。預設值true(使用第一組size做為預設高度)
 */
const DesktopAdProfiles = {
  newsListTopBanner: {
    // 新聞列表頁最上方橫幅(第一屏 banner)
    name: 'news-lists-top-banner',
    path: '/1018855/cnyes_news_all_top_list',
    size: [[970, 250]],
    className: 'cnyes-dfp-top-banner',
    marginTop: 24,
    marginBottom: 24
  },
  NewsDetailAllTop: {
    // 新聞內頁最上方廣告(第一屏 banner)
    name: 'cnyes_news_inside_all_top',
    path: '/1018855/cnyes_news_inside_all_top',
    size: [[970, 250]],
    marginTop: 24,
    marginBottom: 24,
    isLoadImmediately: true
  },
  NewsDetailAllBottom: {
    // 新聞內頁最下方橫幅
    name: 'cnyes_news_inside_bottom_728*90',
    path: '/1018855/cnyes_news_inside_bottom_728*90',
    size: [[728, 90]],
    marginTop: 24,
    marginBottom: 24
  },
  NewsDetailArticle1: {
    // 新聞內頁文中廣告第一則
    name: 'cnyes_news_article_middle_1',
    path: '/1018855/cnyes_news_article_middle_1',
    size: [[728, 90]],
    marginTop: 32,
    marginBottom: 32
  },
  NewsDetailArticleBottom: {
    // 新聞內頁文末橫幅
    name: 'cnyes_news_article_bottom',
    path: '/1018855/cnyes_news_article_bottom',
    size: [[300, 250]],
    marginTop: 35,
    marginBottom: 35
  },
  AsideBannerTop: {
    name: 'aside-banner-top',
    path: '/1018855/cnyes_news_insideright_300*250',
    size: [
      [300, 250],
      [300, 600]
    ],
    enabledDefaultHeight: false
  },
  idleBannerCenter: {
    name: 'idle-banner-center',
    path: '/1018855/cnyes_newsarticle_interstitial',
    size: [[880, 230]]
  },
  interstitial480: {
    name: 'interstitial480',
    path: '/1018855/mobile_interstitial',
    size: [[320, 480]],
    className: 'ad-interstitial',
    checkAdIsLoaded: true
  },
  asideCryptoBannerTop: {
    name: 'aside-crypto-banner-top',
    path: '/1018855/cnyes_news_crypto_300x250',
    size: [[300, 250]]
  },
  asideBannerMid: {
    name: 'aside-banner-mid',
    path: '/1018855/cnyes_news_aside_middle',
    size: [[300, 600]],
    className: 'cnyes-dfp-banner'
  },
  asideBannerBottom: {
    name: 'aside-banner-bottom',
    path: '/1018855/cnyes_news_inside_300*100',
    size: [
      [300, 250],
      [300, 100]
    ]
  },
  asidePopularNewsNative: {
    name: 'index-topic-news-native',
    path: '/1018855/cnyes_news_inside_native_Right',
    size: [['fluid']]
  },
  asidePopularNewsNativeRight2: {
    // 列表頁側邊欄-人氣新聞內原生廣告
    name: 'cnyes_news_inside_native_Right2',
    path: '/1018855/cnyes_news_inside_native_Right2',
    size: [['fluid']]
  },
  detailOutOfPage: {
    name: 'detail-out-of-page',
    path: '/1018855/cnyes_newsarticle_oop_video',
    outOfPage: true,
    className: 'cnyes-dfp-onead',
    hideOnInitial: true
  },
  newslistNativeList1: {
    // 無限滾動列表內原生廣告第一則
    name: 'cnyes_newslist_native_1',
    path: '/1018855/cnyes_newslist_native_1',
    size: [[800, 105]],
    checkAdIsLoaded: true
  },
  newslistNativeList2: {
    // 無限滾動列表內原生廣告第二則
    name: 'cnyes_newslist_native_2',
    path: '/1018855/cnyes_newslist_native_2',
    size: [[800, 105]],
    checkAdIsLoaded: true
  },
  newslistNativeList3: {
    // 無限滾動列表內原生廣告第三則
    name: 'cnyes_newslist_native_3',
    path: '/1018855/cnyes_newslist_native_3',
    size: [[800, 105]],
    checkAdIsLoaded: true
  },
  newslistNativeList4: {
    // 無限滾動列表內原生廣告第四則
    name: 'cnyes_newslist_native_4',
    path: '/1018855/cnyes_newslist_native_4',
    size: [[800, 105]],
    checkAdIsLoaded: true
  },
  newslistNativeList5: {
    // 無限滾動列表內原生廣告第五則
    name: 'cnyes_newslist_native_5',
    path: '/1018855/cnyes_newslist_native_5',
    size: [[800, 105]],
    checkAdIsLoaded: true
  },
  newslistNativeList6: {
    // 無限滾動列表內原生廣告第六則
    name: 'cnyes_newslist_native_6',
    path: '/1018855/cnyes_newslist_native_6',
    size: [[800, 105]],
    checkAdIsLoaded: true
  },
  NewsDetailInterested1: {
    // 內頁您可能感興趣第一則
    name: 'cnyes_newsarticle_list_native_1',
    path: '/1018855/cnyes_newsarticle_list_native_1',
    size: [[850, 105]]
  },
  NewsDetailInterested2: {
    // 內頁您可能感興趣第二則
    name: 'cnyes_newsarticle_list_native_2',
    path: '/1018855/cnyes_newsarticle_list_native_2',
    size: [[850, 105]]
  },
  specialPromotionPopup: {
    name: 'special-promotion-popup',
    path: '/1018855/cnyes_giant_popup',
    className: 'cnyes-dfp-banner',
    size: [['fluid']]
  },
  detailAdPopup: {
    name: 'detail-ad-popup',
    path: '/1018855/cnyes_news_all_popup',
    size: [[900, 500]],
    className: 'cnyes-dfp-banner'
  }
};

export default DesktopAdProfiles;
