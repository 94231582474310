/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';

const Container = styled.div<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => height || 120}px;
  padding: 8px 8px 8px 16px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #e2e8f1;
`;

const LeftContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LoadingSkeleton = styled.div`
  width: 100%;
  border-radius: 6px;
  height: 12px;

  @keyframes pulse-animation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  animation: pulse-animation 1.5s ease-in-out 0.5s infinite;
  background-color: #f6f8fc;
`;

const ShortLoadingSkeleton = styled(LoadingSkeleton)`
  width: 20%;
  background-color: #e2e8f1;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 26px;
`;

const ButtonLoadingSkeleton = styled(LoadingSkeleton)`
  width: 20%;
  max-width: 78px;
  background-color: #e2e8f1;
  height: 100%;
`;

const NewsSkeleton = ({ height, showImage = true }: { height?: number; showImage?: boolean }) => {
  return (
    <Container className='loading-container' height={height}>
      <LeftContent>
        <ShortLoadingSkeleton />
        <LoadingSkeleton />
        <LoadingSkeleton />
        <Flex>
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <ButtonLoadingSkeleton key={`skeleton-${index}`} />
            ))}
        </Flex>
      </LeftContent>
      {showImage && <img src='/assets/icons/skeleton/empty-image.svg' alt='Empty' width={90} height={64} />}
    </Container>
  );
};

export default NewsSkeleton;
