/**
 * https://github.com/dvtng/react-loading-skeleton
 */

import { styled } from '@linaria/react';
import type { CSSProperties } from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type SkeletonProps = {
  width?: number | string;
  height?: number | string;
  circle?: boolean;
  padding?: string;
  margin?: string;
  borderRadius?: number | string;
  isImage?: boolean;
  style?: CSSProperties;
};

const SkeletonWrapper = styled.div<{
  width?: number | string | undefined;
  height?: number | string | undefined;
  circle?: boolean;
  borderRadius?: number | string | undefined;
  margin?: string;
  padding?: string;
  isImage?: boolean;
}>`
  @keyframes loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  background-color: #f6f8fc;
  width: ${({ width }) => (width ? (typeof width === 'string' ? width : `${width}px`) : '100%')};
  height: ${({ height }) => (height ? (typeof height === 'string' ? height : `${height}px`) : '100%')};
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: ${({ padding }) => (padding ? padding : 0)};
  border-radius: ${({ borderRadius, circle }) =>
    circle ? '50%' : typeof borderRadius === 'string' ? borderRadius : `${borderRadius}px`};
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

  &::before {
    content: ' ';
    display: ${({ isImage }) => (isImage ? 'block' : 'none')};
    background: url('/assets/icons/skeleton/empty-image.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 40%;
    height: 40%;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
  }

  ::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, #f6f8fc, #e2e8f1, #f6f8fc);
    transform: translateX(-100%);

    animation-name: loading-skeleton;
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`;

const Skeleton = ({ width = 40, height = 40, circle = false, borderRadius = 6, isImage, style }: SkeletonProps) => {
  return <ReactSkeleton width={width} height={height} borderRadius={borderRadius} style={style} />;
  // return <SkeletonWrapper width={width} height={height} circle={circle} borderRadius={borderRadius} isImage={isImage} style={style} />
};

export const CircleSkeleton = ({
  width = 40,
  height = 40,
  circle = true,
  style,
  borderRadius = '50%'
}: SkeletonProps) => {
  return <Skeleton circle={circle} height={height} width={width} style={style} borderRadius={borderRadius} />;
};

export const SquareSkeleton = ({ width = 40, style }: SkeletonProps) => {
  return <Skeleton height={width} width={width} style={style} />;
};

export const RectangleSkeleton = ({ width = 40, height = 40, borderRadius = 6, style }: SkeletonProps) => {
  return <Skeleton height={height} width={width} borderRadius={borderRadius} style={style} />;
};

export const ImageSkeleton = ({ width = 90, height = 64, style }: SkeletonProps) => {
  return <Skeleton isImage width={width} height={height} style={{ ...style, borderRadius: '6px' }} />;
};
