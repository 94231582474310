/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ANUE_SERVICE_LIMIT = 2;
const ANUE_LINK_LIMIT = 4;

const Container = styled.section`
  width: 100%;
  margin: 35px 0;
  font-size: 14px;
  height: 485px;
`;

const ContentWrapper = styled.div`
  background-color: white;
  padding: 16px;
  margin-top: 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  max-height: 435px;
`;

const NewsServiceWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 107px;
`;

const NewsLinkWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 20px;
`;

const SkeletonAnueService = () => {
  return (
    <Container>
      <Skeleton height={38} borderRadius={6} />
      <ContentWrapper>
        {Array(ANUE_SERVICE_LIMIT)
          .fill('link')
          .map((e, i) => {
            return (
              <NewsServiceWrapper key={`${e}-${i}`}>
                <Skeleton height={107} borderRadius={6} />
              </NewsServiceWrapper>
            );
          })}
        {Array(ANUE_LINK_LIMIT)
          .fill('link')
          .map((e, i) => {
            return (
              <NewsLinkWrapper key={`${e}-${i}`}>
                <Skeleton height={20} borderRadius={6} />
              </NewsLinkWrapper>
            );
          })}
      </ContentWrapper>
    </Container>
  );
};

export default SkeletonAnueService;
