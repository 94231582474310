'use client';

import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import cx from 'classnames';
import type { FC } from 'react';
import { NewsTagGtmEventType } from '..';

const NEWS_TAG_GTM_SECTION = {
  newsTwStockExpert: `${GTMConstants.SECTION_PLACEHOLDER}_台股專家觀點`,
  newsIssue: `${GTMConstants.SECTION_PLACEHOLDER}_議題新聞`,
  newsArticleTag: `${GTMConstants.SECTION_PLACEHOLDER}_文章標籤`,
  newsArticleInterested: `${GTMConstants.SECTION_PLACEHOLDER}_可能感興趣`,
  newsSearchHotTag: `${GTMConstants.SECTION_PLACEHOLDER}_熱門關鍵字`,
  newsSearchNewsTag: GTMConstants.SECTION_PLACEHOLDER,
  newsList: GTMConstants.SECTION_PLACEHOLDER,
  news24h: `${GTMConstants.SECTION_PLACEHOLDER}_主編精選`,
  newsCategoryPopTopic: 'News_Category_時事',
  newsCategoryPopTopicId: 'News_Category_時事內頁'
};
interface NewsTagProp {
  children?: React.ReactNode;
  padding?: string;
  onClick?: (e?: React.MouseEvent) => void;
  link?: string;
  order?: number;
  tag?: string;
  activeTag?: boolean;
  gtmEventType?: NewsTagGtmEventType;
  backgroundColor?: string;
  fontColor?: string;
  isPopTopic?: boolean;
  isPopTopicNews?: boolean;
  isPopTopicNewsId?: boolean;
}

const Tag = styled.a<{
  padding?: string;
  order?: number;
  backgroundColor?: string;
  fontColor?: string;
  isPopTopic?: boolean;
}>`
  display: block;
  text-align: center;
  cursor: pointer;
  border: ${({ isPopTopic }) => (isPopTopic ? '1px solid rgba(255, 255, 255, 0.5)' : '1px solid #e2e8f1')};
  border-radius: 6px;
  color: ${({ fontColor }) => (fontColor ? fontColor : '#515c69')};
  line-height: 15px;
  letter-spacing: 0.5px;
  font-size: 15px;
  padding: ${({ padding }) => (padding ? padding : '8px 12px')};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'white')};
  white-space: nowrap;
  order: ${({ order }) => (order ? order : 'unset')};

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  :hover {
    color: ${({ isPopTopic, fontColor }) => (isPopTopic && fontColor ? fontColor : '#1c1e2f')};
    background-color: ${({ isPopTopic }) => (isPopTopic ? 'transplant' : 'rgba(226, 232, 241, 0.2);')};
  }

  &.active {
    background-color: #e03f19;
    color: white;
  }

  &.active-tag {
    border: solid 1px #e03f19;

    span {
      font-size: 14px;
      line-height: 14px;
      color: #e03f19;
    }
  }
`;

const NewsTag: FC<NewsTagProp> = ({
  padding,
  onClick,
  link = '',
  order,
  gtmEventType,
  tag,
  activeTag = false,
  backgroundColor = 'white',
  fontColor = '#515c69',
  isPopTopic = false,
  isPopTopicNews = false,
  isPopTopicNewsId = false
}) => {
  const handleOnClick = () => {
    if (gtmEventType && tag) {
      sendGTM({
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TAG,
        [GTMEventKey.SECTION]: NEWS_TAG_GTM_SECTION[gtmEventType],
        [GTMEventKey.CLICK_ITEM]: isPopTopicNews
          ? `相關新聞_${tag}`
          : isPopTopic || isPopTopicNewsId
            ? `熱門時事_${tag}`
            : tag
      });
    }
    if (onClick) onClick();
  };

  return (
    <Tag
      href={link}
      onClick={handleOnClick}
      padding={padding}
      order={order}
      title={tag}
      className={cx({ 'active-tag ': activeTag })}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      isPopTopic={isPopTopic}
    >
      <span>{tag}</span>
    </Tag>
  );
};

export default NewsTag;
