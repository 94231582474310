import Anue from './theme/Anue';
import Folder from './theme/Folder';
import NewsLabel from './theme/NewsLabel';
import NewsTag from './theme/NewsTag';

enum NewsTagGtmEventType {
  NEWS_TW_STOCK_EXPERT = 'newsTwStockExpert',
  NEWS_ISSUE = 'newsIssue',
  NEWS_ARTICLE_TAG = 'newsArticleTag',
  NEWS_ARTICLE_INTERESTED = 'newsArticleInterested',
  NEWS_SEARCH_HOT_TAG = 'newsSearchHotTag',
  NEWS_SEARCH_NEWS_TAG = 'newsSearchNewsTag',
  NEWS_LIST = 'newsList',
  NEWS_24H = 'news24h',
  NEWS_CATEGORY_POP_TOPIC = 'newsCategoryPopTopic',
  NEWS_CATEGORY_POP_TOPIC_ID = 'newsCategoryPopTopicId'
}

export { Anue, Folder, NewsLabel, NewsTag, NewsTagGtmEventType };
export default { Anue, Folder, NewsLabel, NewsTag, NewsTagGtmEventType };
