/* eslint-disable import/no-extraneous-dependencies */
'use client';
import { formatTime } from '@fe-common-utils/libs/time';
import { styled } from '@linaria/react';
import { memo } from 'react';

type NewsListProps = {
  label?: React.ReactNode;
  title: React.ReactNode | string;
  newsId: number;
  coverSrc?: string;
  publishAt: number | string;
  target?: '_self' | '_blank';
  breadcrumbs?: string;
  style?: React.CSSProperties;
  Tags?: React.ReactNode;
  activeStorage?: boolean;
  onClickStorage?: (id: number) => () => void;
};

const ListContainerBase = styled.li`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 16px 0;
  gap: 8px;
  overflow-x: hidden;

  &:not(:last-of-type) {
    padding-bottom: 16px;
    border-bottom: 1px solid #e2e8f1;
  }

  &:not(:first-of-type) {
    padding-top: 16px;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 20px;

  span {
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.5px;
    color: #515c69;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background-color: #e2e8f1;
    }
  }
`;

const Title = styled.a`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #383838;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 13px;
  vertical-align: middle;
  max-height: 48px;

  :hover {
    color: #000;
  }

  :visited {
    color: rgba(115, 115, 115, 0.5);
    opacity: 0.5;
  }
`;

const NewsDate = styled.time`
  color: #848d97;
  margin-right: 20px;
  font-size: 13px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  img {
    width: 75px;
    height: 53px;
    border-radius: 4px;
    object-fit: cover;
  }
`;

const StorageButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 12px;

  img {
    width: 28px;
    height: 28px;
    border-radius: 15px;
  }
`;

const TopWrapper = ({ publishAt = 0, breadcrumbs = '' }: { publishAt: number | string; breadcrumbs?: string }) => {
  return (
    <ContentHeader>
      <NewsDate>
        {typeof publishAt === 'string' ? publishAt : formatTime(publishAt, 'MMDD')}
      </NewsDate>
      {breadcrumbs && <span>{breadcrumbs}</span>}
    </ContentHeader>
  );
};

const ImageWrapper = ({
  newsId,
  coverSrc = '',
  activeStorage = false
}: {
  newsId: number;
  coverSrc?: string;
  activeStorage?: boolean;
}) => {
  return (
    <ImageContainer>
      {coverSrc && <img src={coverSrc} alt='news-cover' />}
      {activeStorage && (
        <StorageButton>
          <img
            id={`${newsId}-button`}
            src='/assets/icons/storage/icon-collectbtn-white.svg'
            alt='store favorites news'
          />
        </StorageButton>
      )}
    </ImageContainer>
  );
};

const IndexMobileList = ({
  label = '',
  title,
  newsId,
  coverSrc,
  publishAt,
  breadcrumbs,
  Tags,
  style,
  activeStorage = false
}: NewsListProps) => {
  const url = `/news/id/${newsId}`;

  return (
    <ListContainerBase key={newsId} style={style}>
      <Content>
        <TopWrapper publishAt={publishAt} breadcrumbs={breadcrumbs} />
        <Title href={url} title={title as string}>
          {label} {title}
        </Title>
        {Tags && Tags}
      </Content>
      <ImageWrapper newsId={newsId} coverSrc={coverSrc} activeStorage={activeStorage} />
    </ListContainerBase>
  );
};

export default memo(IndexMobileList);
