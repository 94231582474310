/* eslint-disable @typescript-eslint/no-explicit-any */
const typeOfTest = (type: string) => (thing: any) => typeof thing === type;

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * 以下用法參考
 * https://github.com/axios/axios/blob/v1.x/lib/utils.js
 */

/**
 * Determine if a value is undefined
 *
 * @param {*} val The value to test
 *
 * @returns {boolean} True if the value is undefined, otherwise false
 */
export const isUndefined = typeOfTest('undefined');

/**
 * Determine if a value is a String
 *
 * @param {*} val The value to test
 *
 * @returns {boolean} True if value is a String, otherwise false
 */
export const isString = typeOfTest('string');

/**
 * Determine if a value is a Function
 *
 * @param {*} val The value to test
 * @returns {boolean} True if value is a Function, otherwise false
 */
export const isFunction = typeOfTest('function');

/**
 * Determine if a value is a Number
 *
 * @param {*} val The value to test
 *
 * @returns {boolean} True if value is a Number, otherwise false
 */
export const isNumber = typeOfTest('number');

/**
 * Determine if a value is an Object
 *
 * @param {*} thing The value to test
 *
 * @returns {boolean} True if value is an Object, otherwise false
 */
export const isObject = (thing: null) => thing !== null && typeof thing === 'object';

/**
 * Determine if a value is a Boolean
 *
 * @param {*} thing The value to test
 * @returns {boolean} True if value is a Boolean, otherwise false
 */
export const isBoolean = (thing: boolean) => thing === true || thing === false;

export const isClient: boolean = typeof window !== 'undefined';

export const isServer: boolean = typeof window === 'undefined';

export const progressWidth = (input: number, progressAll: number) => Math.round((input / progressAll) * 100);
