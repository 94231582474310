'use client';

import { styled } from '@linaria/react';
import cx from 'classnames';
import { memo } from 'react';

export interface QuoteProps {
  change: number;
  children: React.ReactNode;
  isAsideUse?: boolean;
  fontSize?: number;
  bold?: number | string;
}

const ColorQuote = styled.span<{ fontSize?: number; bold?: number | string }>`
  color: #515c69;
  font-size: ${({ fontSize }) => `${fontSize ? fontSize : 14}px`};
  line-height: 1;
  letter-spacing: 0.5px;
  font-weight: ${({ bold }) => (bold ? bold : 'normal')};

  &.rise {
    color: #e03f19;
  }

  &.fall {
    color: #206308;
  }

  &.aside {
    font-size: 14px !important;
  }
`;

export function Quote({ change, children, fontSize = 0, bold }: QuoteProps) {
  const status = +change > 0 ? 'rise' : +change < 0 ? 'fall' : 'black';

  return (
    <ColorQuote className={cx('quote-button', `${status}`)} fontSize={fontSize} bold={bold}>
      {children}
    </ColorQuote>
  );
}

export default memo(Quote);
