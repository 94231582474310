/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Container = styled.section`
  width: 100%;
  margin: 35px 0;
  font-size: 14px;
  height: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 38px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 274px;
  border-radius: 6px;
  max-height: 288px;
`;

const FooterWrapper = styled.div`
  height: 6px;
`;

const NewsFeatureReport = () => {
  return (
    <Container>
      <TitleWrapper>
        <Skeleton height={38} borderRadius={6} />
      </TitleWrapper>
      <ImageWrapper>
        <Skeleton height={274} borderRadius={6} />
      </ImageWrapper>
      <FooterWrapper>
        <Skeleton height={6} borderRadius={6} />
      </FooterWrapper>
    </Container>
  );
};

export default NewsFeatureReport;
