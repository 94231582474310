/* eslint-disable @typescript-eslint/no-explicit-any */

import 'anue-fe-sdk';
import 'anue-fe-sdk/web-preset';

import HttpClient from '@fe-common-utils/libs/apis';
import { ServerStatusCodes } from '@fe-news/constants/server-status-codes';

export const apiClient = HttpClient({ host: String(process.env.NEXT_PUBLIC_API_URL) });

export const wsClient = HttpClient({ host: String(process.env.NEXT_PUBLIC_REUTER_API_URL) });

export const cosvcClient = HttpClient({ host: String(process.env.NEXT_PUBLIC_COSVC) });

export const essClient = HttpClient({ host: String(process.env.NEXT_PUBLIC_SEARCH_INFO_URL) });

export const haoClient = HttpClient({ host: String(process.env.NEXT_PUBLIC_HAO_INFO_URL) });

export const s3Client = HttpClient({ host: String(process.env.NEXT_PUBLIC_S3) });

export const memberClient = HttpClient({
  host: String(process.env.NEXT_PUBLIC_MEMBER_URL),
  headers: {
    'Content-Type': 'application/json',
    'X-System-Kind': 'LOBBY',
    'X-Platform': 'WEB'
  }
});

// TODO: 重新針對會員登入後帶入 Token 的處理方式改寫

const AnueNetwork = anue.shared.get('library.net');
const NEXT_PUBLIC_MEMBER_ALWAYS_RIGHT_URL = String(process.env.NEXT_PUBLIC_MEMBER_ALWAYS_RIGHT_URL);

type Method = 'GET' | 'DELETE' | 'POST' | 'PUT';

type SendRequestProps<M extends Method, B = undefined> = {
  url: string;
  method: M;
  body?: B;
};

export const memberWithAuthClient = {
  get: (url: string) => sendRequest<'GET'>({ url, method: 'GET' }),
  post: <T>(url: string, body?: T) => sendRequest<'POST', T>({ url, method: 'POST', body }),
  delete: <T>(url: string, body?: T) => sendRequest<'DELETE', T>({ url, method: 'DELETE', body }),
  put: <T>(url: string, body?: T) => sendRequest<'PUT', T>({ url, method: 'PUT', body })
};

const sendRequest = <M extends Method, B = undefined>(props: SendRequestProps<M, B>) => {
  const driver = AnueNetwork.getDriver();

  return (
    driver.send({
      url: `${NEXT_PUBLIC_MEMBER_ALWAYS_RIGHT_URL}${props.url}`,
      auth: true,
      method: props.method,
      body: props.body
    }) || Promise.reject()
  );
};

export function tryCatch(function_: any) {
  try {
    return function_ && function_();
  } catch (error: any) {
    // use any custom handler here
    console.error(`['API Fetch Error']: ${error.toString()}`);
  }
}

// 處理 API 回傳的資料
export function processData(response: any, defaultValue: any = []) {
  if (response.statusCode === ServerStatusCodes.SUCCESS) {
    const responseData = response?.items ?? defaultValue;
    return responseData;
  }

  return defaultValue;
}

export const removeUndefinedKeys = (object: { [x: string]: unknown }) => {
  const newObject: { [x: string]: any } = {};

  for (const key of Object.keys(object)) {
    if (object[key]) newObject[key] = object[key];
  }

  return newObject;
};
