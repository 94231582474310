import News from './theme/News';
import NewsList from './theme/NewsList';
import AsideNews, { AsideNewsGtmEventType } from './theme/AsideNews';
import CategoryNewsList from './theme/CategoryNews';
import { CategoryNewsListGtmEventType } from './theme/CategoryNews';
import IndexMobileList from './theme/IndexMobileList';
import SearchNews from './theme/SearchNews';

export {
  News,
  NewsList,
  AsideNews,
  CategoryNewsList,
  IndexMobileList,
  SearchNews,
  CategoryNewsListGtmEventType,
  AsideNewsGtmEventType,
};
export default {
  News,
  NewsList,
  AsideNews,
  CategoryNewsList,
  IndexMobileList,
  SearchNews,
  CategoryNewsListGtmEventType,
  AsideNewsGtmEventType,
};
