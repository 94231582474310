enum CardSize {
  DesktopNews = 130,
  DesktopDate = 48,
  MobileListSecondAd = 282,
  DesktopPopTopicDate = 66,
  DesktopPopTopicNewsAndAd = 120,
  MobilePopTopicAd = 112,
  MobilePopTopicNews = 146,
  MobilePopTopicDate = 81
}

export default CardSize;
