'use client';

import { styled } from '@linaria/react';
import cx from 'classnames';

const AnueButton = styled.button<{
  width?: number;
  height?: number;
  fontSize?: number;
}>`
  border-width: 0;
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  cursor: pointer;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: center;
  border-radius: 2px;
  color: #8f8f8f;
  background-color: white;
  width: ${({ width }) => `${width || 105}px`};
  height: ${({ height }) => `${height || 44}px`};

  :hover {
    color: #e03f19;
  }

  &.active {
    background-color: #e03f19;
    color: white;
  }
`;

const Anue = ({
  children,
  active,
  onClick,
  width,
  height,
  fontSize,
}: {
  children: React.ReactNode;
  active?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
  width?: number;
  height?: number;
  fontSize?: number;
}) => {
  return (
    <AnueButton
      className={cx({ active })}
      onClick={onClick}
      width={width}
      height={height}
      fontSize={fontSize}
    >
      {children}
    </AnueButton>
  );
};

export default Anue;
