/* eslint-disable import/no-extraneous-dependencies */
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import cx from 'classnames';
import { useCallback } from 'react';

type Selector = {
  category: string | number;
  name: string;
};

interface TopSelectorProps {
  selectors: Selector[];
  selector: string | number;
  onClick?: (select: string | number) => void;
}

const TabsWrapper = styled.ul`
  display: flex;
  align-items: center;
  gap: 18px;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    justify-content: space-between;
  }
`;

const Tab = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #848d97;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    height: 49px;
    flex: 1;
    text-align: center;
    line-height: 49px;
  }

  &.active {
    color: #e03f19;

    &:before {
      content: unset;

      @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
        content: '';
        position: absolute;
        display: block;
        width: 20px;
        height: 2px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.9;
        box-shadow: 0 2px 6px 0 rgba(224, 63, 25, 0.5);
        background-color: #e03f19;
      }
    }
  }

  &:not(:last-of-type):after {
    position: absolute;
    content: '';
    display: block;
    width: 2px;
    height: 16px;
    background-color: #e2e8f1;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
      content: unset;
    }
  }
`;

const TopSelector = ({ selectors, selector, onClick }: TopSelectorProps) => {
  const selectTab = selectors?.find((tab: Selector) => tab.category === selector) ?? { category: '', name: '' };

  const onClickTab = useCallback(
    (category: string | number) => () => {
      if (typeof onClick !== 'function') return;

      onClick(category);
    },
    []
  );

  return (
    <TabsWrapper>
      {selectors.map(({ category, name }) => (
        <Tab key={category} onClick={onClickTab(category)} className={cx({ active: selectTab?.category === category })}>
          {name}
        </Tab>
      ))}
    </TabsWrapper>
  );
};

export default TopSelector;
